.back {
  position: absolute;
  top: 113px;
  left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fbbf19;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}
.back img:hover{
transform: skewX(3);
transition: ease-in 2s;
}