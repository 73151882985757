.institute-hero {
	background-image: url(../../Assets/img/group-photo.png);
	background-color: #000000a5;
	background-blend-mode: multiply;
	padding: 7rem 1rem 5rem;
	/* min-height: 676px; */
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
}

.institute-hero-heading {
	font-weight: 700;
	color: #fff;
	font-size: 45px;
	line-height: 1.2;
	max-width: 869px;
	align-content: center;
	text-align: center;
	align-self: center;
}

.institute-hero-paragraph {
	font-size: 22px;
	align-self: center;
	text-align: center;
	max-width: 869px;
	color: #fff;
}

.institute-main {
	display: flex;
	flex-direction: column;
	gap: 5rem;
}

.institute-card-container {
	display: flex;
	flex-direction: column;
	gap: 7.5rem;
	justify-content: center;
	align-items: flex-start;
	padding: 1rem;
	width: 100%;
}

.institute-card-content {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 1rem;
	max-width: 1134px;
}

.institute-image-card {
	max-width: 100%;
	width: 250px;
	order: unset;
	margin-top: -3rem;
	margin-bottom: -2rem;
	z-index: -1;
}

.institute-text-card {
	max-width: 555px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1rem;
}

.institute-text-card-heading {
	font-weight: 700;
	font-size: 30px;
	line-height: 1.3;
}

.institute-text-card-description {
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6;
	margin-top: -0.5rem;
}

.institute-sponsors-div {
	display: flex;
	gap: 5rem;
	flex-direction: column;
	padding: 0 1rem;
}

.institute-sponsors {
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	max-width: 1134px;
	align-self: center;
	justify-content: center;
	align-items: center;
}

.institute-sponsors-heading {
	font-size: 30px;
	text-align: center;
	font-weight: 700;
}

.institute-sponsor-logo {
	max-width: 80px;
	height: fit-content;
}

.numbers-container {
	background-image: url('../../Assets/img/background-blue.png');
	background-position: center;
	background-repeat: repeat;
	background-size: cover;
	width: 100%;
	min-height: 400px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 5rem 1rem;
}

.numbers {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	gap: 5rem;
	align-items: center;
	margin: auto;
}

.empower {
	padding: 0 1rem;
	display: flex;
	flex-direction: column;
	gap: 4rem;
}

.empower-text {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
}

.empower-heading {
	font-weight: 700;
	font-size: 28px;
	line-height: 1.3;
}

.empower-pickup {
	font-size: 18px;
	line-height: 1.6;
}

.reverse {
	flex-direction: column-reverse;
}

@media only screen and (min-width: 768px) {
	.institute-hero-heading {
		font-size: 60px;
		line-height: 1.3;
	}

	.institute-hero-paragraph {
		font-size: 30px;
		line-height: 1.6;
	}

	.institute-main {
		display: flex;
		flex-direction: column;
		gap: 8rem;
	}

	.institute-card-container {
		margin: 100px auto 0;
		padding: 0 2rem;
		gap: 8.5rem;
		align-items: flex-start;
	}

	.institute-image-card {
		margin-top: -4rem;
		min-width: 345px;
	}

	.institute-text-card {
		gap: 1.5rem;
	}

	.institute-text-card-heading {
		font-size: 32px;
	}

	.institute-text-card-description {
		font-size: 20px;
		line-height: 1.8;
	}

	.numbers-container {
		flex-direction: column;
		padding: 5rem 2rem;
	}

	.numbers {
		flex-direction: row;
		gap: 10rem;
		align-items: flex-start;
	}

	.institute-sponsors-div {
		padding: 0 2rem;
	}

	.empower-heading {
		font-weight: 700;
		font-size: 34px;
	}

	.empower-pickup {
		font-size: 20px;
	}
}

@media only screen and (min-width: 1110px) {
.institute-card-content{
	gap: 200px;
}
}
@media only screen and (min-width: 1024px) {
	.institute-hero {
		height: 676px;
		max-height: calc(100vh - 90.33px);
		margin-top: 90.33px;
		padding-top: 5rem;
	}

	.institute-hero-heading {
		font-size: 72px;
		line-height: 86.4px;
	}

	.institute-hero-paragraph {
		font-size: 30px;
		line-height: 45px;
	}

	.institute-card-container {
		margin-top: 6rem;
		max-width: 1134px;
		gap: 200px;
		align-items: center;
		padding: 0 1rem;
	}

	.institute-card-content {
		flex-direction: row;
		gap: 100px;
		min-width: 1134px;
		max-width: fit-content;
	}

	.institute-image-card {
		order: unset;
	}

	.institute-text-card {
		gap: 3rem;
	}

	.institute-text-card-heading {
		font-size: 38px;
		line-height: 52.74px;
	}

	.institute-text-card-description {
		margin-top: -2rem;
		font-size: 20px;
	}

	.institute-main {
		display: flex;
		flex-direction: column;
		gap: 200px;
	}

	.empower {
		margin-bottom: 8rem;
	}

	.empower-heading {
		font-size: 34px;
	}

	.reverse {
		flex-direction: row;
	}
}
