.pro-dev-hero {
  background-image: url(../../Assets/img/p-d-hero.png);
  background-color: #000000a5;
  background-blend-mode: multiply;
  padding: 7rem 1rem 5rem;
  /* min-height: 676px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.pro-dev-hero-heading {
  font-weight: 700;
  font-size: 45px;
  line-height: 1.2;
  max-width: 1195px;
}

.pro-dev-hero-para {
  font-size: 22px;
  line-height: 42.5px;
  max-width: 905px;
}

.pro-dev-sec-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  padding: 100px 1rem;
}

.sec-2-text-box {
  max-width: 662px;
  color: #050038;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sec-2-head {
  font-size: 32px;
  font-weight: 700;
  line-height: 53px;
}

.sec-2-para {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
}

.sec-2-img-div {
  width: fit-content;
}

.pro-dev-sec-3 {
  padding: 100px 1rem;
  background: #fbbf190f;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 90px;
}

.sec-3-text-box {
  max-width: 963px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  color: #050038;
}

.sec-3-head {
  font-weight: 700;
  max-width: 740px;
  font-size: 32px;
  line-height: 1.5;
}

.sec-3-cta {
  background-color: #050038;
  color: #fff !important;
  border-radius: 4px;
  padding: 10px 24px;
  margin-top: 1rem;
  width: fit-content;
}

.sec-3-card-section {
  display: flex;
  flex-direction: column;
  gap: 74px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pro-dev-sec-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 52px;
  padding: 100px 1rem;
}

.sec-4-text-box {
  max-width: 856px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  color: #050038;
}

.sec-4-head {
  font-weight: 700;
  font-size: 32px;
}

.sec-4-para {
  font-size: 20px;
  line-height: 1.7;
}

.our-process {
  background-color: #3b6bd30f;
  padding: 100px 1rem;
  display: flex;
  flex-direction: column;
}

.process-head {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.process-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 140px 1rem;
  color: #050038;
}

.portfolio-text-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.portfolio-head {
  font-size: 32px;
  font-weight: 700;
}

.portfolio-para {
  max-width: 856px;
  line-height: 1.6;
}

.portfolio-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  justify-content: center;
  align-items: center;
}

.goodbye {
  background-image: url(../../Assets/img/p-d-bottom.png);
  background-color: #000000a5;
  background-blend-mode: multiply;
  padding: 7rem 1rem 5rem;
  min-height: 598px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  /* margin-bottom: 8rem; */
}

.goodbye-text {
  max-width: 626px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.goodbye-head {
  font-weight: 700;
  font-size: 32px;
}

.goodbye-para {
  line-height: 42.5px;
  font-size: 20px;
}
#cbtn {
  margin: 3rem auto 3rem !important;
  text-align: center;
}
.slick-slide > div {
  margin: 0 auto;
}
.slick-list {
  margin: 0 auto;
}
#img-card {
  border-radius: 12px;
  background: #f5f5f5;
  color: #666;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
#img-card:hover {
  box-shadow: 0px -2px 25px 4px rgba(20, 19, 19, 0.75);
  -webkit-box-shadow: 0px -2px 25px 4px rgba(20, 19, 19, 0.75);
  -moz-box-shadow: 0px -2px 25px 4px rgba(20, 19, 19, 0.75);
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}
#img {
  width: 100%;
  object-fit: cover;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

@media only screen and (min-width: 425px) {
  #img-card {
    margin: 10px 0;
  }
}
@media only screen and (min-width: 768px) {
  /* .slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
} */
  .pro-dev-hero-heading {
    font-size: 60px;
    line-height: 1.5;
  }

  .pro-dev-hero-para {
    font-size: 30px;
  }

  .pro-dev-sec-2 {
    align-items: center;
    flex-direction: row;
    gap: 2rem;
  }

  .sec-2-text-box {
    max-width: 50%;
  }

  .sec-3-card-section {
    flex-direction: row;
  }

  .process-cards {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 19px;
  }

  .sec-2-head,
  .sec-3-head,
  .sec-4-head,
  .process-head,
  .portfolio-head,
  .goodbye-head {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1024px) {
  .pro-dev-hero {
    height: 676px;
    max-height: calc(100vh - 90.33px);
    margin-top: 90.33px;
    padding-top: 5rem;
  }

  .pro-dev-hero-heading {
    font-size: 72px;
  }

  .pro-dev-hero-para {
    font-size: 32px;
  }

  .pro-dev-sec-2 {
    padding: 150px 2rem;
    gap: 94px;
  }

  .sec-2-text-box {
    max-width: 662px;
  }

  .pro-dev-sec-3 {
    padding: 120px 2rem;
  }

  .sec-2-para,
  .sec-4-para {
    line-height: 42.5px;
  }
}
