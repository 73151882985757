/* form */

.visit {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

@media (max-width: 768px) {
}
.visit .bg-img {
	background-image: url('../../Assets//img/idea-bg.png');
}
.visit-heading {
	background: #ffffff;
	border-bottom: 0.2px solid rgb(219, 228, 247);
	box-sizing: border-box;
	border-radius: 4px 4px 0px 0px;
	height: 69px;
	font-size: 15px;
	line-height: 18px;
	color: #050038;
	cursor: pointer;
}
.bg-color {
	background: #ecf0f9;
	border-radius: 4px 4px 0px 0px;
	font-weight: bold;
	color: #050038;
}
/* .visit form {
  background-color: #fff;
} */
.visit .close {
	margin-left: auto;
	cursor: pointer;
	opacity: 0.9 !important;
}

.visit .heading {
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
	color: #10162b;
}
.visit form label {
	margin-bottom: -90px;
	background-color: #fff;
	/* z-index: 5; */
	margin-left: 20px;
}
.visit .p {
	border-bottom: 1px solid #f1f2f3;
	font-size: 16px;
	line-height: 19px;
	color: #a5abbe;
	padding-bottom: 1em;
	margin-top: 4em;
}
.visit form input,
.visit form select,
.visit form textarea {
	margin-top: -10px;
	height: 56px;
	border: 0.4px solid rgba(59, 107, 211, 0.8);
	box-sizing: border-box;
	border-radius: 4px;
}
.visit form textarea {
	z-index: -555666 !important;
	height: 156px;
}
.visit form input:active,
.visit form select:active {
	outline: none !important;
	border-style: none !important;
	z-index: -1;
}
.div {
	z-index: -5555555555555;
}
.visit form .required,
.required-non {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: -8px;
	margin-left: 25px;
	color: #bd1919;
}
.visit form .required-non {
	opacity: 0;
}
.visit .file-upload label {
	border: 0.4px dashed #3b6bd3;
	box-sizing: border-box;
	border-radius: 4px;
	width: 222px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 10px;
}
#reason {
	position: relative;
	z-index: 99999 !important;
}
.visit form .button {
	width: 195px;
	height: 60px;
	background: #050038;
	border-radius: 6px;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-top: 3em;
}
input[type='file']::file-selector-button,
input[type='file']::-webkit-file-upload-button {
	background-color: brown;
}
