.mini-cards {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 100px;
}

.mini-card {
	max-width: 286px;
	transition: 0.5s !important;
	border-radius: 12px;
	border: 1px solid #e0e0e0;
}

.mini-card:hover {
	box-shadow: 0 1px 10px #c1c1c1;
}
.img-radius{
	border-radius: 12px 12px 0 0;
}