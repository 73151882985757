.how-partnering {
  background: #f5f9ff;
}
.partner .heading {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #050038;
}
.partner .button {
  width: 195px;
  height: 60px;
  background: #050038;
  border-radius: 6px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.partner .button a {
  color: #ffffff;
  text-decoration: none;
}
.partner .text {
  font-weight: normal;
  font-size: 22px;
  line-height: 42px;
  text-align: center;
  color: #050038;
}
.partner .top .heading {
  font-size: 42px;
  line-height: 51px;
}
.partner ul {
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;

  color: #050038;
}
.partner ul li {
  list-style: none;
}
.partner ul li .heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  text-align: left;
  color: #050038;
  margin-bottom: 0.5em;
}
.partner ul li .text {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  line-height: 34px;
}
.partner ul .star-img {
  width: 30px;
  height: 30px;
}

.partner .big-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

/* form */

.partner-form {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: #ffff;
}
@media (max-width: 768px) {
  .partner-form {
    position: relative;
  }
}
.partner-form .close {
  margin-left: auto;
  cursor: pointer;
  opacity: 0.9 !important;
}
.partner-form .heading {
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #10162b;
}
.partner-form form label {
  margin-bottom: -90px;
  background-color: #fff;
  /* z-index: 1; */
  margin-left: 20px;
}
.partner-form form input,
.partner-form form select {
  margin-top: -10px;
  height: 56px;
  border: 0.4px solid rgba(59, 107, 211, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
}
.partner-form form input:active,
.partner-form form select:active {
  outline: none !important;
  border-style: none !important;
}
.partner-form form .required,
.required-non {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: -8px;
  margin-left: 25px;
  color: #bd1919;
}
.partner-form form .required-non {
  opacity: 0;
}
.partner-form .file-upload label {
  border: 0.4px dashed #3b6bd3;
  box-sizing: border-box;
  border-radius: 4px;
  width: 222px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}
.partner-form form .button {
  width: 195px;
  height: 60px;
  background: #050038;
  border-radius: 6px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 7em;
}
input[type="file"]::file-selector-button,
input[type="file"]::-webkit-file-upload-button {
  background-color: brown;
}

.partner-form .img {
  width: 20px;
  height: 20px;
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 1em;
  margin-top: 3em;
}
.partner-form .img img {
  object-fit: contain;
  width: 100%;
}
