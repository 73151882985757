
.hire-hero {
	background-image: url(https://thebulb.africa/static/media/headerBig.5f258fa5.svg);
	background-repeat: repeat-x;
	padding: 10rem 1rem 8rem;
	gap: 1rem;
	color: #10152c;
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.hire-hero > h1 {
	font-weight: 700;
	font-size: 42px;
}

.hire-hero > p {
	font-size: 24px;
	line-height: 42px;
	max-width: 970px;
}

.hire-card-container {
	display: flex;
	flex-direction: column;
	gap: 7.5rem;
	justify-content: center;
	padding: 1rem;
	width: 100%;
}

.hire-card-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 2rem;
}

.hire-image-card {
	max-width: 100%;
	width: 500px;
	order: unset;
	z-index: -1;
}

.hire-text-card {
	max-width: 550px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1rem;
}

.hire-text-card-heading {
	font-weight: 700;
	font-size: 30px;
	line-height: 1.3;
}

.hire-text-card-description {
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6;
	margin-top: -0.5rem;
}

.reverse {
	flex-direction: column-reverse;
}

.sponsors {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	margin: 10rem auto;
	max-width: 1164px;
}

.sponsors-heading {
	text-align: center;
	font-weight: 700;
	font-size: 30px;
}

.sponsors-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3rem;
	flex-wrap: wrap;
	padding: 0 1rem;
}

.sponsors-logo > img {
	max-width: 6rem;
}

.sponsors-logo > a {
	transition: 0.5s !important;
}

.sponsors-logo > a:hover {
	opacity: 1 !important;
}

.hire-mini-cards {
	display: flex;
	flex-direction: column;
	gap: 5rem;
}

.hire-mini-text {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.hire-mini-heading {
	font-size: 30px;
	font-weight: 700;
}

.hire-mini-para {
	max-width: 970px;
	font-size: 20px;
}

@media only screen and (min-width:768px) {
	.hire-card-container {
		margin: 0 auto;
		padding: 0 2rem;
		gap: 8.5rem;
	}

	.hire-image-card {
		/* margin-top: -4rem; */
		min-width: 345px;
	}

	.hire-text-card {
		gap: 1rem;
	}
	
	.hire-text-card-heading {
		font-size: 30px;
	}
	
	.hire-text-card-description {
		font-size: 20px;
		line-height: 1.6;
	}
}

@media only screen and (min-width:1024px) {
	.hire-card-content {
		flex-direction: row;
		align-items: center;
		gap: 3rem;
	}

	.hire-image-card {
		order: unset;
	}

	.reverse {
		flex-direction: row;
	}
}