.staff {
	position: relative;
	width: 246px;
	height: 246px;
	color: 'fff';
}
.staff .staff-img {
	width: 246px;
	height: 246px;
}
.staff .staff-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.staff .overley-pix {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 246px;
	background: rgba(16, 22, 43, 0.5);
	border-radius: 4px;
	width: 246;
}

.staff .overley-pix:hover {
	background-color: transparent;
	transition: ease-in 0.3s;
}
