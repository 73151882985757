.social-media {
	flex-direction: column;
	align-items: center;
}
@media (max-width: 650px) {
	.social-media {
		flex-direction: column;
		align-items: center;
	}
}

.share {
	cursor: pointer;
}
.like-circle {
	background-color: #10162c;
	color: #fff;
	margin-bottom: -9px;
	margin-left: -10px;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 10px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
