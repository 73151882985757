.course-card {
	border-radius: 24px;
	border: 0.82px solid #E0E0E0;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 1rem;
	padding: 2rem;
}

.course-name {
	font-weight: 700;
	font-size: 20px;
	margin-top: 1rem;
	max-width: 200px;
}

.course-info,
.duration {
	font-size: 18px;
	color: #727272;
}

.course-cta {
	background-color: #10152c;
	border-radius: 5px;
	padding: 0.75rem 2rem;
	margin-top: 1rem;
	width: fit-content;
	color: #fff !important;
}

@media only screen and (min-width: 1024px) {
	.course-card {
		max-width: 884px;
		min-height: 506px;
	}
}