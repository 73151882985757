/* h1 {
  text-align: center;
} */

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0px 0;
  padding: 40px 0;
}

.timeline-container::after {
  background-color: #d6d8e6;
  content: "";
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: calc(100% - 70px);
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 0px;
  position: relative;
  width: 400px;
  max-width: 70%;

  text-align: right;
}

/* .timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
} */

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}
.timeline-item:nth-child(odd) .circle::after {
  background-color: #fdbf17;
  content: "";
  position: absolute;
  /* left: calc(50% - 2px); */
  top: 40%;
  left: 15px;
  width: 100px;
  height: 1px;
}
.timeline-item:nth-child(even) .circle::after {
  background-color: #13162d;
  content: "";
  position: absolute;
  /* left: calc(50% - 2px); */
  top: 40%;
  right: 15px;
  width: 100px;
  height: 1px;
}
.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
}
.timeline-item:nth-child(even) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  background-color: #fdbf17;
  border: 5px solid #f3dda2;
  border-radius: 50%;
  position: absolute;
  top: calc(0%);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 88;
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
  background-color: #13162d;
  border: 5px solid #d6d8e6;
  border-radius: 50%;
  position: absolute;
  top: calc(0%);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 88;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  /* .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
  } */

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }


}
