.incubator {
	padding: 1rem;
	margin-top: 5rem;
}

.incubator-hero {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 36px;
  margin-top: 56px;
	padding: 1rem;
}
.incubator-hero-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 593px;
  padding: 1rem 0;
}
.incubator-hero-img {
		display: none;
}
.incubator-hero-head {
    font-size: 40px;
    font-weight: 700;
    color: #050038;
}
.incubator-hero-text {
    font-size: 20px;
    color: #050038;
}
.under-hero {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    margin-top: 149px;
    max-width: 700px;
    gap: 2rem;
}
.under-hero-head {
  font-size: 32px;
  font-weight: 700;
  color: #050038;
  line-height: 140%;
}
.under-hero-text {
  font-size: 20px;
  font-weight: 400;
  color: #050038;
  line-height: 37px;
}
.incubator-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1241px;
  margin: 0 auto;
  margin-top: 151px;
  gap: 47px;
  padding: 3rem 1rem 3rem 2rem;
  background-image: url(../../../Assets/img/incubator-backgound-1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.incubator-list-head {
  font-size: 32px;
  font-weight: 700;
  color: #050038;
}

.incubator-list-text-2 {
  display: flex;
  flex-direction: column;
  gap: 42px;
  max-width: 713px;
  font-size: 20px;
  font-weight: 400;
  color: #050038;
}
.incubator-list-text-2 li::marker {
  color: #fbbf19;
}
.look-out-for {
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
  justify-content: center;
}
.look-out-for h2 {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  color: #050038;
  text-align: center;
  margin-bottom: 82px;
}
.look-out-for-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 100px;
}
.incubator-bottom {
	min-height: 500px;
	position: relative;
  margin: 0 auto;
	width: 100%;
  /* margin-top: 200px; */
	/* min-height: fit-content; */
  display: flex;
  flex-direction: column;
  align-items: center;
	justify-content: center;
  gap: 37px;
	padding: 41.2px 1rem;
}

.incubator-bottom-head {
  font-size: 32px;
  font-weight: 700;
  color: #050038;
	margin-top: -5rem;
}
.incubator-bottom-imgss {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
	margin-top: 0rem;
}

.incubator-bottom-img {
  width: 250px;
	opacity: 0.6 !important;
}

.incubator-bottom-img:hover {
  opacity: 1 !important;
}

.incubator-bottom::after {
	content: '';
	position: absolute;
	top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
	/* min-height: 350px; */
	background-image: url(../../../Assets//img/sponsors-bg.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	opacity: 1;
}

.incubator-final {
	padding: 50px 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.incubator-final > h3 {
	font-weight: 700;
	font-size: 32px;
	line-height: 1.3;
	text-align: center;
	line-height: 44.8px;
	max-width: 573px;
}

.incubator-final > p {
	font-weight: 400;
	font-size: 20px;
	line-height: 1.8;
	text-align: center;
	line-height: 37.2px;
	max-width: 490px;
}


@media only screen and (min-width: 768px) {
	.incubator-bottom {
		margin-top: 200px;
		min-height: 450px;
	}
	
	.incubator-bottom-imgss {
		flex-direction: row;
	}
	
	.incubator-final {
		padding: 100px 1rem;
	}
}

@media only screen and (min-width: 1024px) {
	.incubator-hero {
		justify-content: center;
		padding: 0;
	}
	
	.incubator-hero-img {
		display: block;
		width: 100%;
		max-width: 539px;
		border-radius: 24px;
	}

	.incubator-list {
		padding: 75px 260px;
	}
}