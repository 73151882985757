@media (min-width: 992px) {
    .dropdown-menu .dropdown-toggle:after {
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
    }
  
    .dropdown-menu .dropdown-menu {
      margin-left: 0;
      margin-right: 0;
    }
  
    .dropdown-menu li {
      position: relative;
    }
    .nav-item .submenu {
      display: none;
      position: absolute;
      left: 100%;
      top: -7px;
    }
    .nav-item .submenu-left {
      right: 100%;
      left: auto;
    }
    .dropdown-item:hover{
      background: #eef0f9; 
    }
  
    .dropdown-menu > li:hover {
      background: #eef0f9;
      border-left: 2px solid #FBBF19;;
    }
    .dropdown-menu > li:hover > .submenu {
      display: block;
    }
  }
  