.bg-door {
  /* background-color: aqua; */
  width: 100%;
  height: 100vh;
  background-image: url("../../../Assets/img/front-door.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.overley {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
.overley p {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  text-align: center;
  color: #ffffff;
  width: 500px;
}
.overley .virtual {
  flex: none;
  background: #fbbf19;
  border-radius: 3px;
  height: 60px !important;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
}
.overley .physical {
  flex: none;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  border-radius: 3px;
  height: 60px !important;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
