.privacy-hero {
  background-image: url(https://thebulb.africa/static/media/headerBig.5f258fa5.svg);
  background-repeat: repeat-x;
  padding: 8rem 1rem 0 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.privacy-hero-head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
}
.privacy-main {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #10152c;
  margin: 1rem 2rem 5rem 2rem;
}
.privacy-head {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;
  margin-bottom: 1rem;
}
.privacy-text {
  max-width: 711px;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 40px;
}
.privacy-list {
  /* width: 736px; */
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
}
.privacy-main li {
  margin-left: 1.5rem;
}

.privacy-main ol>li {
  margin-left: 0rem;

  counter-increment: OrderedList;
  list-style: none;
}

.privacy-main ol :before {
  /* list-style-type: lower-alpha; */
  content: "(" counter(OrderedList, lower-alpha) ") ";
}


@media only screen and (min-width:768px) {
    .privacy-hero{
  padding: 6rem 1rem 0 3rem;
    }
.privacy-main{
  margin-left: 3rem;

}
}

@media only screen and (min-width:1024px) {
    .privacy-hero{
        padding: 8rem 1rem 0 117px;
          }
      .privacy-main{
        margin-left: 117px;
      
      }
}