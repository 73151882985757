.number {
	color: #fff;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.counter {
	font-size: 32px;
}

.counter-text {
	font-size: 22px;
}

@media only screen and (min-width: 768px) {
	.number {
		gap: 40px;
	}

	.counter {
		font-size: 42px;
	}

	.counter-text {
		font-size: 32px;
	}
}