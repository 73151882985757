.c-a-main {
  max-width: 400px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: flex-start;
  /* max-width: 30%; */
  /* flex-wrap: wrap; */
}
.c-a-main img {
  width: 80px;
  padding: 2%;
  background-image: url(../../Assets/img/ellipse-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.c-a-main h3 {
  max-width: 400px;
  font-size: 24px;
  font-weight: 600;
  padding-left: 5px;
  color: #050038;
  text-align: center;
}
.c-a-main p {
  max-width: 302px;
  font-size: 18px;
  padding-left: 5px;
  color: #413d67;
  line-height: 32.4px;
  text-align: left;
}
