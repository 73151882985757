.community {
}
.community .button {
  height: 56px;
  background: #050038;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}
.community .heading {
  font-style: normal;
  font-weight: bold;
  line-height: 41px;
  color: #050038;
}

.community .top {
  margin-top: 6em;
}

.community .top .img-section img {
  object-fit: contain;
  width: 100%;
  border-radius: 24px;
}
.community .top .right-section .text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
}
.community .top .right-section .button {
  width: 185px;
}
.community .possibilities {
  background: rgba(59, 107, 211, 0.08);
}

.community .possibilities .possibilities-card {
  width: 287px;
  left: 212px;
  top: 1078px;
  background: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  line-height: 24px;
}
.community .possibilities .possibilities-card .card-img {
  height: 60px;
  width: 60px;
}

.community .possibilities .possibilities-card .heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #050038;
}
.community .btn-white {
  background: #ffffff;
  box-shadow: 2px 2px 2px rgba(59, 107, 211, 0.08);
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 185px;
  color: #3b6bd3;
}

/* join */
.join-container {
  min-height: 50vh;
}
.join {
}
.join .heading {
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;

  color: #050038;
}

.join .join-card {
  background: #ffffff;
  border: 1px solid rgba(59, 107, 211, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(59, 107, 211, 0.08);
  border-radius: 6px;
  width: 346px;
}
.join .join-card .company-logo {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.join .join-card .company-logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.join .join-card .heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  color: #050038;
}
.join .join-card .text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  color: #050038;
  min-height: 200px;
}
.join .join-card .btn {
  width: 213px;
  height: 56px;
  left: 1061px;
  top: 761px;
  color: #ffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  background: #050038;
  border-radius: 6px;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
