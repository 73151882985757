.incubator-cards{
    max-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.incubator-cards-img{
    width: 100px;
    margin: 0 auto;
}
.incubator-cards-head{
    max-width: 259px; 
    font-size: 20px;
    font-weight: 700;
    line-height: 37px;
    color: #050038;    
}
.incubator-cards-text{
    font-size: 18px;
    line-height: 37px;
    color: #050038;
}