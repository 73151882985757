.tour-bg-door {
  width: 100%;
  height: 100vh;
  background-image: url("../../Assets/img/front-door.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: -1;
}

.tour-bg-door .overley {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
.tour-bg-door .container{
  height: 100%;
}
.tour-bg-door .overley .container p {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  text-align: center;
  color: #ffffff;
  width: 500px;
}
.tour-bg-door .overley .container .virtual {
  flex: none;
  background: #fbbf19;
  border-radius: 3px;
  height: 60px !important;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: black;
  cursor: pointer;
}
.tour-bg-door .overley .container a{
  text-decoration: none;
}
.tour-bg-door .overley .container .physical {
  flex: none;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  border-radius: 3px;
  height: 60px !important;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .tour-bg-door .overley .container p {
    font-size: 30px !important;
    width: 100% !important;
    line-height: 40px !important;
    font-weight: bold;
  }
}
