.blog-post {
  color: #10162b;
  font-weight: normal;
  font-size: 20px;
  line-height: 195%;
}
.blog-post .date-section {
  font-size: 16px;
  line-height: 19px;
  color: #9ea3b4;
}

.blog-post .img-section {
  /* width: 100%; */
  height: 500px;
}
.blog-post .img-section img {
  object-fit: cover;
  width: 100%;
  width: 100%;
}
.blog-post .img-section img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.blog-post .title {
  font-size: 36px;
  line-height: 44px;
}

.blog-post .comments-section .number-of-comment {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
.blog-post .comments-section .comments .comment-circle {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background: #ffedbc;
}
.blog-post .comments-section .comments .comments-right {
  font-size: 18px;
  line-height: 172%;
  color: #28314f;
}
.blog-post .comments-section .comments .comments-right .respondent {
  font-size: 22px;
  line-height: 27px;
  color: #10162b;
}
.blog-post .comments-section .comments .comments-right .date {
  font-size: 14px;
  line-height: 17px;
  color: #a5abbe;
}
.blog-post .comments-section .comments .comments-right button,
.blog-post .reply-section form button {
  background: #fbbf19;
  border-radius: 4px;
  height: 51px;
  width: 146px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #10162b;
}