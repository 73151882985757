.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.34);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999;
  text-align: center;
  font-size: 18px;
  line-height: 186%;
}

.modal button {
  width: 170px;
  height: 56px;

  background: #050038;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  text-align: center;
  margin: 0 auto;
  margin-top: 2em;
  color: #ffffff;
}

.img{
  width: 20px;
  height: 20px;
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 1em;
}
.modal .img img{
  object-fit: cover;
  height: 100%;
}