.c-a-body {
  max-width: 1240px;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  background-color: #fbbf1909;
  padding: 83px;
  border-radius: 24px;
  padding: 5rem 1rem;
}

.explore-hero {
  background-image: url(../../Assets/img/careers-img.png);
  background-color: #000000a5;
  background-blend-mode: multiply;
  padding: 7rem 1rem 5rem;
  min-height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.explore-hero-heading {
  font-weight: 700;
  color: #fff;
  font-size: 45px;
  line-height: 1.2;
  display: flex;
  align-content: center;
  text-align: center;
  align-self: center;
}

.explore-hero-paragraph {
  font-size: 22px;
  align-self: center;
  text-align: center;
  max-width: 869px;
  color: #fff;
}

.hero-imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 15px 15px;
  background-color: #fff;
  width: 80%;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 50px auto;
  gap: 1.5rem;
}

.hero-imgs img {
  max-width: 4rem;
  display: flex;
  margin: 0 auto;
}
.hero-imgs > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hero-imgs > div > p {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.c-a-head {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.career-props {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5rem;
}
.courses-full {
  margin: 0 auto;
  max-width: 1300px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  gap: 8rem;
  padding-left: 10px;
  padding-right: 10px;
}

.bottom-x {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 100px;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  background-image: url(../../Assets/img/lag.png);
  background-color: #000000a5;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10rem 1rem 10rem;
}

.bottom-x h2 {
  max-width: 100%;
  font-size: 30px;
  font-weight: 700;
}

.bottom-x p {
  font-size: 18px;
}

.the-form {
  background-color: #10152c;
  font-size: 16px;
  font-weight: 300;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  width: fit-content;
  color: #fff !important;
  margin: 2rem auto 0;
  align-content: center;
}

.facilitators {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px;
}
.facilitators-main {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.facilitators > h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 2rem;
}
.facilitator {
  background-color: rgba(5, 0, 56, 0.05);
  border-radius: 12px;
  display: flex;
  max-width: 1260px;
  width: fit-content;
  align-self: center;
}

#jui {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  background-color: transparent;
  padding: 65px 0 30px;
  border-radius: 12px 12px 0 0;
}
#jui img {
  max-width: 100px;
  border-radius: 50%;
  filter: drop-shadow(0px 9.65139px 11.5817px rgba(0, 0, 0, 0.5));
}
.social-icons {
  display: flex;
  margin-bottom: 0.5rem;
}
#jui > h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
#jui > p {
  max-width: 243px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
#jui button {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fbbf19;
}
.aboutFaci {
  display: flex;
  max-width: 800px;
  border-radius: 0 0 12px 12px;
  padding: 30px;
  text-align: center;
}
#r-f {
  display: flex;
  flex-direction: column;
  background: transparent;
  gap: 2rem;
}
.close-button {
  display: flex;
  align-self: center;
  color: white;
  padding: 9px 24px;
  gap: 8px;
  width: 89px;
  height: 48px;
  background: #050038;
  border-radius: 4px;
}

.partners {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 110px 0 100px;
  gap: 3rem;
}
.partners h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
.partners img {
  max-width: 179px;
}

@media only screen and (min-width: 768px) {
  .facilitator {
    flex-direction: row;
    margin: 0 35px;
  }
  #jui {
    padding: 65px 5px 5px;
    background-color: rgba(5, 0, 56, 0.05);
    border-radius: 12px;
  }

  .aboutFaci {
    text-align: left;
    padding: 0;
  }

  #r-f {
    padding: 68px;
  }
  .explore-hero {
    padding-top: 10rem;
  }
  .close-button {
    align-self: flex-end;
  }
  
  .explore-hero-heading {
    font-size: 60px;
    line-height: 1.3;
  }

  .explore-hero-paragraph {
    font-size: 30px;
    line-height: 1.6;
  }
  .hero-imgs {
    position: relative;
    display: flex;
    top: -50px;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    align-self: center;
    padding: 25px 0 25px;
    max-width: 1121px;
  }
  .hero-imgs img {
    max-width: 4rem;
    display: flex;
    margin: auto 0;
  }
  .hero-imgs > div {
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 1023px) {
  .facilitators-main {
    flex-direction: row;
    gap: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .facilitator {
    flex-direction: row;
  }
  #r-f {
    padding: 68px 67px 68px 100px;
  }
  .hero-imgs {
    display: flex;
    justify-content: space-evenly;
    padding: 43px 0 43px;
    max-width: 1121px;
  }
  .hero-imgs img {
    max-width: 7rem;
    display: flex;
  }
  .hero-imgs > div {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
  }
}
