.nav-link.dropdown-toggle::after {
	margin-top: 0;
	display: none;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	margin-left: 10px;
}
.dropdown-menu .dropdown-toggle:after {
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropdown-menu .dropdown-menu {
	margin-left: 0;
	margin-right: 0;
}

.dropdown-menu li {
	position: relative;
}

.nav-item .submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
}
.nav-item .submenu-left {
	right: 100%;
	left: auto;
}
.dropdown-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 180px;
}

.dropdown-item:hover {
	background: #eef0f9;
}

.dropdown-menu > li:hover {
	background: #eef0f9;
	border-left: 2px solid #fbbf19;
}
.dropdown-menu > li:hover > .submenu {
	display: block;
}
.dropdown-item a {
	margin-left: 0;
	padding-left: 0;
}

.padFirst li:first-child {
	margin-top: 20px;
}

.padFirst li:last-child {
	margin-bottom: 20px;
}

.active-mobile:hover,
.active-mobile:focus,
.active-mobile:active {
	background: #eef0f9;
	color: #10162b;
	position: relative;
}

.active-mobile:hover::before,
.active-mobile:focus::before,
.active-mobile:active::before {
	content: '';
	width: 4.48px;
	height: 100%;
	background: #fbbf19;
	top: 0;
	left: 0;
	position: absolute;
}
