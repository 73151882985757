.reply-section {
  margin-top: 5em;
}
.reply-section form {
}
.reply-section .heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
.reply-section .heading span {
  font-weight: normal;
  font-size: 18px;
}
.reply-section form textarea,
.reply-section form input {
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.reply-section form textarea:active,
.reply-section form input:active {
  outline: none !important;
}
