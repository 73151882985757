html {
  scroll-behavior: smooth;
}
.career {
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #050038;
}
.career a {
  color: #050038;
  text-decoration: none;
}
.career .heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 27px;
  color: #050038;
}

.career .job-card {
  background: #ffffff;
  border: 1px solid #ecf0f9;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(59, 107, 211, 0.05);
  border-radius: 6px;
}
.career .job-card:hover {
  border: 1px solid #ecf0f9;
  box-shadow: 15px 15px 15px rgba(59, 107, 211, 0.05);
}
.career .job-card .role {
  font-size: 22px;
  line-height: 27px;
  color: #050038;
}
.career .job-card .detail-section {
  margin-left: -1em;
}
.career .job-card .detail-section span {
  font-weight: bold;
}
.career .job-card .btn {
  width: 164px;
  height: 56px;
  background: #050038;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}
.career-hero {
  background-image: url(../../Assets/img/flags.png);
  background-color: #000000a5;
  background-blend-mode: multiply;
  padding: 7rem 2rem 5rem;
  /* min-height: 40vh; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.career-hero-heading {
  font-size: 4.5rem;
  font-weight: 700;
  text-align: center;
}
.career-hero-para {
  max-width: 905px;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  line-height: 42px;
}
.t-a-l-main {
  margin: 100px auto;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 0.5em;
  justify-content: center;
}
.t-a-l-main h2 {
  display: flex;
  justify-content: center;
  font-size: 38px;
  font-weight: 700;
  color: #050038;
  text-align: center;
  margin-bottom: 57px;
}
.t-a-l-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 100px;
}
.benefits {
  background: #fef4d7;
  padding: 63px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #050038;
}
.benefits-head {
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 3rem;
}
.benefits-main {
  display: flex;
  flex-direction: row;
  gap: 8rem;
  font-weight: 400;
  font-size: 18px;
  line-height: 42px;
}
.benefits-text-1,
.benefits-text-2 {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.benefits-text-1 li::marker,
.benefits-text-2 li::marker {
  font-size: 1.5em;
  vertical-align: middle;
}
.c-footer {
  margin: 10rem 0 5rem;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #050038;
}
.c-footer>p{
  max-width: 891px;
}
@media only screen and (max-width: 600px) {
  .benefits-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }
  .t-a-l-main {
    margin-top: 5rem;
  }
  .career-hero-heading {
    font-size: 3rem;
  }
  .career-hero-para {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .career-hero {
    height: 676px;
    max-height: calc(100vh - 90.33px);
    margin-top: 90.33px;
    padding-top: 5rem;
  }

  .career-hero-para {
    line-height: 45px;
  }
}
