.courses-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 3rem;
	align-items: center;
	padding: 4rem 1rem;
}

.course-heading {
	max-width: 752px;
	font-size: 32px;
	line-height: 51.2px;
	font-weight: 700;
	text-align: center;
	margin: 0 auto;
}

.africa {
	border-bottom: 2px solid #28afe9;
}

.provide {
	max-width: 888px;
	width: 100%;
	text-align: center;
	margin: 0 auto;
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;

}

.background {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-image: url(../../Assets/img/background-effect.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto;
	width: 100%;
	padding: 1rem;
	min-height: 163px;
}

.what-we-provide {
	font-size: 32px;
	font-weight: 700;
	max-width: 596px;
	line-height: 51.2px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-self: center; */
}

.about-provide {
	padding: 1rem;
	font-size: 20px;
	line-height: 37.2px;
	max-width: 585px;
	width: 100%;
	/* display: none; */
}

.academy-main {
	display: flex;
	flex-direction: column;
	background-color: #FEF4D7;
	align-items: center;
	padding: 100px 1rem 100px 2rem;
	width: 100%;
}

.academy-head {
	margin-bottom: 60px;
	margin-left: -1rem;
	text-align: center;
	color: #050038;
	font-size: 32px;
	font-weight: 700;
}

.academy-list {
	display: flex;
	flex-direction: column;
	align-self: center;
	gap: 0.5rem;
	line-height: 50px;
	/* width: 100%; */
	color: #050038;
	font-size: 24px;
	/* max-width: 100%; */
}

.academy-list li::marker {
	color: #fbbf19;
}

.academy-bottom {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	text-align: center;
	padding: 5rem 0;
	margin-bottom: 100px;
}

.academy-bottom-head {
	color: #050038;
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 16px;
}

.academy-bottom-text {
	max-width: 649px;
	line-height: 37px;
	color: #050038;
	font-size: 20px;
}

.bg-img {
	position: absolute;
	width: 450px;
	opacity: 5%;
}

.bg-img-1 {
	top: 0;
	right: -12rem;
	display: none;
	/* display: block; */
}

.bg-img-2 {
	bottom: -7rem;
	left: -12rem;
	transform: scaleX(-1);
}

.startup-hero {
	display: flex;
	gap: 2rem;
	flex-direction: column;
	padding: 1rem;
	margin-top: 5rem;
	max-width: 100%;
	background-image: url(../../Assets/img/ladies-2.png);
	background-color: #000000a5;
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 7rem 1rem 5rem;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

.startup-hero-text {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-width: 644px;
	align-items: center;
	text-align: center;
}

.startup-heading {
	font-size: 42px;
	font-weight: 700;
	/* color: #10152c; */
	color: #fff;
}

.startup-para {
	max-width: 649px;
	line-height: 37.2px;
	font-weight: 400;
	font-size: 20px;
	color: #fff;
}

.get-started {
	background-color: #10152c;
	border-radius: 5px;
	padding: 0.75rem 2rem;
	margin-top: 2rem;
	color: #fff !important;
	width: fit-content;
}

.startup-img {
	display: none;
}

.ladies {
	width: 100%;
}

@media only screen and (min-width: 768px) {
	.startup-hero {
		flex-direction: row;
		padding: 2rem 2rem 2rem 1rem;
		background-image: none;
		background-color: #fff;
	}

	.startup-hero-text {
		color: #10152c;
		justify-content: flex-start;
		align-items: flex-start;
	}
	
	.startup-heading,
	.startup-para {
		color: #10152c;
		text-align: left;
	}

	.startup-img {
		display: flex;
		width: 100%;
		max-width: 392px;
		border-radius: 12px;
		border: 4px solid #10152c;
	}
}

@media only screen and (min-width: 1024px) {
	.startup-hero {
		height: 80vh;
		gap: 10rem;
		justify-content: center;
		align-items: center;
	}

	.startup-img {
		height: 480px;
		position: relative;
		display: flex;
		justify-content: center;
	}

	.ladies {
		position: absolute;
		top: 43.5px;
		right: 43.5px;
	}
}