.cw-hero {
  padding: 7rem 1rem 5rem;
  /* min-height: 40vh; */
  background-image: url(../../Assets/img/desk.png);
  background-color: #000000a5;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.cw-hero-heading {
  font-weight: 700;
  max-width: 1110px;
  font-size: 72px;
  line-height: 88px;
  text-align: center;
}

.cw-hero-para {
  max-width: 905px;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
}
.cw-creative {
  padding: 130px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
}
.cw-creative-heading {
  max-width: 736px;
  font-weight: 700;
  font-size: 38px;
  line-height: 140%;
}
.cw-creative-para {
  max-width: 840px;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
}
.cw-list-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(124, 117, 197, 0.05);
  padding: 116px 16px 80px;
  gap: 5rem;
}
.cw-list-heading {
  font-weight: 700;
  font-size: 38px;
  text-align: center;
}
.cw-list-text {
  display: flex;
  flex-direction: row;
  gap: 15rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  list-style-type: none;
}
.cw-list-text1,
.cw-list-text2 {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow-x: hidden;
}

.cw-list-text1 li::before,
.cw-list-text2 li::before {
  padding-right: 16px;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f14a";
  color: #37b34a;
  /* color: #050038; */
}
.cw-contemporary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 152px 16px;
}
.cw-contemporary-background {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url(../../Assets/img/develop.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  padding: 1rem;
  min-height: 163px;
}
.cw-contemporary-heading {
  font-weight: 700;
  font-size: 38px;
  line-height: 140%;
  max-width: 577px;
  text-align: center;
}
.cw-contemporary-para {
  max-width: 577px;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
}
.trusted {
  padding: 50px 16px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.trusted-heading {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 50px;
  text-align: center;
}
.trusted-imgss {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}
.trusted-img {
  display: flex;
  width: 100px;
}
.cw-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 16px;
  gap: 1.5rem;
  background: #fef4d7;
}
.cw-footer-heading {
  font-weight: 700;
  font-size: 38px;
  max-width: 475px;
  text-align: center;
}
.cw-footer-para {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  max-width: 577px;
}
.cw-footer-button {
  border-radius: 5px;
  color: #10152c;
  padding: 0.75rem 1.5rem;
  border: 2px solid #10152c;
  text-decoration: none;
  width: fit-content;
  background-color: #fef4d7 !important;
}
.cw-footer-button:hover {
  background-color: #10152c !important;
  color: #fef4d7 !important;
}
.hugo,
.intelytics {
  margin: auto 0;
}
@media only screen and (max-width: 610px) {
  .cw-hero-heading {
    font-size: 50px;
    line-height: 50px;
  }
  .cw-hero-para {
    font-size: 20px;
  }
  .cw-creative {
    padding: 50px 16px;
  }
  .cw-list-main {
    padding: 50px 16px;
    gap: 3rem;
  }
  .cw-list-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .cw-list-text1,
  .cw-list-text2 {
    gap: 1rem;
  }
  .cw-contemporary {
    padding: 50px 20px;
  }
  .trusted-imgss {
    display: flex;
    flex-direction: column;
  }
}
@media (611px <= width <= 800px) {
  .cw-list-text {
    gap: 5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .cw-hero {
    height: 676px;
    max-height: calc(100vh - 90.33px);
    margin-top: 90.33px;
    padding-top: 5rem;
  }

  .cw-hero-para {
    line-height: 45px;
  }
}
