/* form */

.idea {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

@media (max-width: 768px) {
}
.idea .bg-img {
	background-image: url('../../Assets//img/idea-bg.png');
}
.idea form {
	background-color: #fff;
}
.idea .close {
	margin-left: auto;
	cursor: pointer;
	opacity: 0.9 !important;
}
.idea .heading {
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
	color: #10162b;
}
.idea form .form-group label {
	margin-bottom: -90px;
	background-color: #fff;
	/* z-index: 5; */
	margin-left: 20px;
}
.idea form input,
.idea form select,
.idea form textarea {
	margin-top: -10px;
	height: 56px;
	border: 0.4px solid rgba(59, 107, 211, 0.8);
	box-sizing: border-box;
	border-radius: 4px;
}
.idea form textarea {
	z-index: -555666 !important;
	height: 156px;
}
.idea form input:active,
.idea form select:active {
	outline: none !important;
	border-style: none !important;
}
.idea form .required,
.required-non {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: -8px;
	margin-left: 25px;
	color: #bd1919;
}
.idea form .required-non {
	opacity: 0;
}
.idea .file-upload label {
	border: 0.4px dashed #3b6bd3;
	box-sizing: border-box;
	border-radius: 4px;
	width: 222px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 10px;
}
.idea form .button {
	width: 195px;
	height: 60px;
	background: #050038;
	border-radius: 6px;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-top: 3em;
}
input[type='file']::file-selector-button,
input[type='file']::-webkit-file-upload-button {
	background-color: brown;
}
