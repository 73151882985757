.cta {
	background-color: #10152c;
	border-radius: 5px;
	border: none;
	padding: 0.75rem 1.5rem;
	color: #fff !important;
	text-decoration: none;
	width: fit-content;
}

.cta-light {
	background-color: #fff;
	border: 2px solid #10152c;
	border-radius: 5px;
	padding: 0.75rem 1.5rem;
	width: fit-content;
	text-decoration: none;
	color: #10152c !important;
	transition: 0.5s !important;
}

.cta:hover{
	background-color:#fff ;
	color: #10152c !important;
	border: 2px solid #10152c;
}
.cta-light:hover {
	background-color: #10152c;
	color: #fff !important;
}