.zee {
  z-index: 10;
}
.ic-width{
  max-width: 358px;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 100px 20px 100px;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
	overflow-x: hidden;
}
.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}
.hero-text-heading {
  font-weight: 700;
  font-size: 42px;
  line-height: 120%;
}
.hero-text-para {
  font-weight: 400;
  font-size: 20px;
  line-height: 186%;
}
.hero-img {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  position: relative;
  max-width: 500px;
}
.hero-img-box-top {
  background-image: url(../../Assets/img/dots.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  display: flex;
  position: absolute;
  width: 50px;
  height: 100px;
  top: -20px;
  right: -50px;
}
.hero-img-box {
  position: relative;
  border-radius: 12px;
  z-index: 2;
}
.hero-img-box-bottom {
  background-image: url(../../Assets/img/dots.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  position: absolute;
  width: 50px;
  height: 100px;
  bottom: -20px;
  left: -50px;
}
.heart {
  width: 15px;
  display: inline-block;
}
.hero-text-emoji {
  background-color: rgba(241, 235, 218, 1);
  display: flex;
  font-family: "Inter", sans-serif;
  flex-direction: row;
  font-size: 14px;
  border: 3px solid rgba(241, 235, 218, 1);
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: rgba(219, 169, 15, 1);
  border-radius: 20px;
  padding: 0px 2.1197px;
}
.whats-new {
  border: 1px solid #ffffff;
  border-radius: 29px;
  padding: 0px 10.1197px;
  background-color: white;
}

.talents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  gap: 1rem;
  margin-bottom: 137px;
  background-color: #f3f3f6;
}
.talents-img {
  display: flex;
  align-self: center;
  max-width: 400px;
  padding: 56px 0;
}
.talents-text {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 499px;
  text-align: left;
  gap: 2rem;
}
.talents h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
}
.talents p {
  font-weight: 400;
  font-size: 20px;
  line-height: 186%;
}
.unlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: 137px 0 48px;
}
.unlock h2 {
  font-weight: 700;
  max-width: 774px;
  font-size: 36px;
  line-height: 120%;
}
.unlock p {
  max-width: 614px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 186%;
}

.communities-card-container {
  display: flex;
  flex-direction: column;
  gap: 7.5rem;
  justify-content: center;
  padding: 0 1rem;
}

.communities-card-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.communities-image-card {
  max-width: 510px;
  display: flex;
  justify-content: left;
  order: unset;
}

.communities-text-card {
  max-width: 490px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.communities-text-card-heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.3;
}

.communities-text-card-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
}
.we-offer {
  margin: 150px 0;
  background-color: #f3f3f6;
  padding: 3em 0;
}
.we-offer-main {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.we-offer h2 {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  color: #050038;
  text-align: center;
  margin-bottom: 82px;
}
.we-offer-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.communities-mini-cards {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	margin-bottom: 100px;
	padding: 1rem;
}

.group {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.mini-card-img {
	object-fit: cover;
	width: 400px;
	height: 250px;
}

@media only screen and (min-width: 768px) {
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    background-image: none;
    color: #10152c;
    padding: 100px 20px 100px;
    gap: 3rem;
  }
  .hero-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    align-items: flex-start;
    gap: 1rem;
    justify-content: center;
  }
  .hero-text-emoji {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-radius: 25px;
  }
  .hero-text-heading {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
  }
  .hero-text-para {
    font-weight: 400;
    font-size: 20px;
    line-height: 186%;
  }
  .hero-img {
    margin-left: 30px;
    margin-right: 50px;
    display: flex;
    justify-content: left;
    align-self: flex-start;
  }

  .communities-card-container {
    margin: 2rem auto 0;
    padding: 0 2rem;
  }

  .communities-image-card {
    min-width: 490px;
  }

  .communities-text-card-heading {
    font-size: 32px;
  }

  .communities-text-card-description {
    font-size: 20px;
    line-height: 1.8;
  }
  .talents {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }



	.communities-mini-cards {
		flex-direction: row;
		flex-wrap: wrap;
		padding: 2rem;
	}

	.group {
		flex-direction: column;
		justify-content: space-between;
	}

	.mini-card-img {
		width: 100%;
		height: 100%;
	}
}

@media only screen and (min-width: 982px) {
	.end-group {
		flex-direction: row;
	}
}

@media only screen and (min-width: 1024px) {
  .hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    background-image: none;
    color: #10152c;
    padding: 200px 20px 100px;
  }
  .hero-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    width: 583px;
  }
  .hero-text-emoji {
    display: flex;
    flex-direction: row;
  }
  .hero-text-heading {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
  }
  .hero-text-para {
    font-weight: 400;
    font-size: 20px;
    line-height: 186%;
  }

  .color1 {
    border: 10px solid #fff3d1;
    border-radius: 6px;
  }
  .color2 {
    border: 10px solid #dfefff;
    border-radius: 6px;
  }

  .communities-card-content {
    flex-direction: row;
    gap: 6rem;
  }

  .communities-image-card {
    order: unset;
  }

  .communities-text-card-heading {
    font-size: 38px;
  }
  .we-offer {
    margin-bottom: 185px;
  }
  .we-offer-main {
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
  }
  .we-offer h1 {
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-weight: 700;
    color: #050038;
    text-align: center;
    margin-bottom: 82px;
  }
  .we-offer-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 32px;
  }
  .talents {
    display: flex;
    flex-direction: row;
    gap: 5rem;

  }
 
}

@media only screen and (min-width: 1298px) {
	.end-group {
		flex-direction: column;
	}
}