@import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
@import '../../../node_modules/react-big-calendar/lib//css/react-big-calendar.css';
@import '../../../node_modules/react-big-calendar/lib//addons/dragAndDrop/styles.css';

body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "proxima-nova",sans-serif;
    color: #10152C;
}
#particles-js {
    width: 100%;
    height: 100%;
    background-size: cover;
}
.uk-position-fixed {
    position: fixed!important;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9;
}
.errorMsg{
    font-size: 14px;
    color:#ff0000;
    font-style: italic;
    padding-top: 3px;
}

.succesMsg{
    font-size: 14px;
    color:rgb(25, 211, 25);
    font-style: italic;
    padding-top: 3px; 
}
.feed-section .feedContent span * {
    font-size: 16px !important;
    font-weight: normal !important;
    color: #10152c !important;
}

.csutom { 
    display: none !important; 
}
.tk-proxima-nova {
    font-family: "proxima-nova",sans-serif;
}
.tk-proxima-nova-condensed {
    font-family: "proxima-nova-condensed",sans-serif;
}
.tk-proxima-nova-extra-condensed {
    font-family: "proxima-nova-extra-condensed",sans-serif; 
}
.Navcolor{
    background-color: #10152c;
}
.navbar a.navbar-brand img {
    max-width: 150px;
    padding: 10px 0px;
}
.navbar ul.navbar-nav li.nav-item .nav-link {
    font-family: 'proxima-nova', sans-serif;
    color: #fff;
    margin-left: 25px;
    font-size: 16px;
}
.navbar ul.navbar-nav li.nav-item .nav-link:hover, .navbar ul.navbar-nav li.nav-item .nav-link:focus {
    color: #fdbf14;
    outline: none;
}
.blockclass {
    margin-top: 50px;
}


/* nav#navTop button.dropdown-toggle {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    outline: none;
    box-shadow: none;
}
nav#navTop button.dropdown-toggle[aria-expanded="true"] {
    background-color: #fff;
    opacity: 1;
}
nav#navTop button.dropdown-toggle:after{
    display: none;
}
nav#navTop .dropdown-menu {
    width: calc(100% - 30px);
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0px;
    padding: 0;
} */



.slider{
    background-color: #10152c;
    min-height: 100vh;
    overflow: hidden;
}
.slider_inner {
    padding: 80px 0 1em;
    min-height: 100vh;
}
.slider_inner img{
    max-width: 70%;
    margin: 0 auto;
    display: table;
    transition: all 0.5s ease;
    animation: lightupDown 100s infinite;
    -webkit-animation: lightupDown 10s infinite;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.slider-content h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 500;
    font-family: "Rubik", sans-serif;
}
.slider-content h1 a,
.Typewriter .typing {
    display: block;
    color: #FDBF14;
    text-decoration: none;
    font-weight: 600;
    font-size: 38px;
    font-family: "proxima-nova", sans-serif !important;
    
}
.slider-bottom{
    width: 100%;
    position: absolute;
    bottom: 25px;
    left: 0;
    z-index: 1;
    
}
.slider-bottom-inner{
    max-width: 33.33%;
    flex: 0 0 33.33%;
    text-align: center;
    position: relative;
}
.slider-bottom-inner img,
.slider-bottom-inner h3{
    cursor: pointer;
}
.slider-bottom .slider-bottom-inner img{
    margin-bottom: 10px;
    width: 18px;
    transition: all 0.5s ease;
    animation: upDown 1.5s infinite;
    -webkit-animation: upDown 1.5s infinite;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.w-1px {
    width: 1px !important;
}
.slider-bottom .slider-bottom-inner h3{
    color: #fff;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 24px;
}
.slider-hover-content{
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
}
.slider-hover-inner{
    max-width: 33.33%;
    flex: 0 0 33.33%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    visibility: hidden;
    height: 0%;
    opacity: 0;
    z-index: 1;
    position: relative;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
.slider-hover-inner:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(66, 61, 82, 0.6);
    z-index: -1;
}
.indviduals-bg{
    background-image: url('https://thebulb.africa/Images/homepage/individuals-bg.jpg');
}
.corporation-bg{
    background-image: url('https://thebulb.africa/Images/homepage/corporation-bg.jpg');
}
.ngo-bg{
    background-image: url('https://thebulb.africa/Images/homepage/ngo-bg.jpg');
}
.slider-hover-inner h3{
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    padding-bottom: 10px;
    font-weight: 600;
    text-shadow: -1px 2px 7px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.5px;
}
.slider-hover-inner ul {
    list-style: none;
    padding-left: 10px;
}
.slider-hover-inner ul li a{
    color: #fff;
    padding: 4px 0;
    display: block;
    font-family: 'proxima-nova', sans-serif;
    text-decoration: none;
    font-size: 22px;
    letter-spacing: 0.3px;
}
.slider-hover-inner ul li a:hover,
.slider-hover-inner ul li a:focus{
    color: #FDBF14;
}
.individual-bg-slide,
.corporation-bg-slide,
.ngo-bg-slide{
    visibility: visible;
    height: 100%;
    opacity: 1;
}
.slider-bottom-inner.indiarrow h3,
.slider-bottom-inner.ngoratearrow h3,
.slider-bottom-inner.copratearrow h3 {
    color: #fdbf14;
}
.slider-bottom-inner.indiarrow img,
.slider-bottom-inner.ngoratearrow img,
.slider-bottom-inner.copratearrow img  {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
}

button.dropdown-toggle[aria-expanded="true"] span.navbar-toggler-icon {
    position: relative;
    height: 2px;
    background-color: #10152c;
    color: #fff;
    filter: url();
    transform: rotate(45deg);
}
button.dropdown-toggle[aria-expanded="true"] span.navbar-toggler-icon:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    height: 2px;
    width: 16px;
    background-color: #10152c;
}
button.dropdown-toggle[aria-expanded="true"] {
    border-color: #fff;
    padding-bottom: 5px;
    background-color: #fff;
    border-radius: 5px;
}
button.dropdown-toggle{
    outline: none;
}
.whowe-bg {
    background: url('https://thebulb.africa/Images/background/the-hub-bg.jpg');
    height: 320px;
}
.banner-title {
    font: Bold 45px/65px Proxima-Nova;
    letter-spacing: 0;
    color: #FFFFFF;
}
.parallax {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.tech_incubation .inner h2 {
    font: Bold 30px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
}
.tech_incubation .inner p {
    font: 16px/24px Rubik;
    letter-spacing: 0.02px;
    color: #10152C;
}
.mission-vision h3{
    font: Bold 28px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
}
.mission-vision p{
    font: 16px/27px Rubik;
    letter-spacing: 0;
    color: #10152C;
}
.team-bg{
    background-image: url('https://thebulb.africa/Images/background/team.jpg');
    height: 309px;
}
.member-inner {
    margin-bottom: 1.3em;
    display: inline-block;
    width: 100%;
}
.member-inner img{
    max-height: 250px;
    margin: 0 auto;
    display: table;
    height: 250px;
    object-fit: cover;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.member-inner .member-title{
    font: Bold 22px/27px Proxima-Nova;
    color: #10152C;
    padding: 10px 0px 3px;
    margin-bottom: 0;
}
.member-inner .designation{
    font: 16px/27px Rubik;
    color: #10152C;
}
.advisteam-bg{
    background-image: url('https://thebulb.africa/Images/background/advisoryteam.jpg');
    height: 309px;
}
.fixed-tab {
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 9;
    width: 5px;
}
.fixed-tab .fix-tab {
    height: 35px;
    width: 3px;
    background-color: #ddd;
    display: inline-block;
    margin-bottom: 25px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    position: relative;
}
.fixed-tab .active-tab{
    background-color: #FDBF14;
}
.fixed-tab .fix-tab:hover,
.fixed-tab .fix-tab:focus{
    background-color: #FDBF14;
}
.fixed-tab .fix-tab a {
    height: 100%;
    width: 100%;
    display: block;
}
.fixed-tab .fix-tab:last-child {
    margin-bottom: 0;
}
span.tabtitle {
    position: absolute;
    right: 70px;
    top: 0;
    width: auto;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #eeee;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border-radius: 2px;
    box-shadow: 0px 0px 12px rgba(0,0,0,.051);
    font-family: Proxima-Nova;
    font-size: 15px;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
}
.fix-tab:hover span.tabtitle, .fix-tab:focus span.tabtitle {
    right: 10px;
    visibility: visible;
    opacity: 1;
    pointer-events: none;
}
footer.footer {
    background-color: #10152C;
    padding: 50px 0px 30px;
}
.footer img {
    max-width: 120px;
}
footer .footer-links h5{
    font: 16px/27px Proxima-Nova;
    letter-spacing: 0;
    color: #FFFFFF;
    font-weight:700;
    margin-bottom: .6em;
}
footer .footer-links{
    padding-bottom: 1.55em;
}
footer .footer-links ul{
    margin-bottom: 0;
}
footer .footer-links ul li,
footer .footer-links ul li a{
    font: 14px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 100;
}
footer .subscribe-form label{
    font: 14px/30px Proxima-Nova;
    color: #FFFFFF;
}
footer .subscribe-form input{
    font: 14px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C5C;
    border-color: #707070;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    min-height: 45px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    outline: none !important;
    box-shadow: none !important;
}
footer .subscribe-form button{
    background: #FDBF14 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 4px 0px;
    font: 14px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    border-color: #FDBF14;
    outline: none !important;
    box-shadow: none !important;
}
footer .copyright{
    padding-top: 1.5em;
    font: 12px/15px Proxima-Nova;
    letter-spacing: 0;
    color: #ECECEC;
    margin-bottom: 0;
}
.contactus-Main{
    margin-top: 73px;
}
.bg-light-gray{
    background-color: #F2F2F2;
}
.address{
    margin-bottom: 1.8em;
}
.address h4,.address h1 {
    font: 30px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    position: relative;
    font-weight: bold;
    padding-bottom: 20px;
}
.address p{
    font: 18px/1.5 Rubik;
    letter-spacing: 0;
    color: #666666;
}
.contact-first .contact-buttons button.btn {
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #707070;
    font: 16px/19px Proxima-Nova;
    color: #10152C;
    padding: 14px 50px;
    margin-bottom: 15px;
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
}
.contact-first .contact-buttons button.btn:hover,
.contact-first .contact-buttons button.btn:focus,
.contact-first .contact-buttons button.btn:active{
    background-color: #000;
    color: #fff;
}
.address-contact p {
    font-size: 16px;
}
.address p strong {
    font-weight: 500;
}
.address h5{
    font: 22px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    font-weight: bold;
    margin-bottom: 3px;
}
.address-contact p strong {
    color: #10152C;
    min-width: 54px;
    font-weight: 500;
}
.mt-73{
    margin-top: 73px;
}
.googlemap{
    height: 350px;
    width: 100%;
    max-width: 100%;
}


/* Co-workspace */
.co-workspace h2{
    font: 48px/normal Proxima-Nova;
    letter-spacing: -2px;
    color: #10152C;
    font-weight: 600;
    padding-bottom: 5px;
}
.co-work-slider .coverflow__container__1P-xE {
    /* width: 100% !important; */
    background-color: transparent !important;
    background: transparent !important;
}
.co-work-slider figure {
    -webkit-box-reflect: unset !important;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2) !important;
}
.co-work-slider figure img {
    box-shadow: none;
    height: inherit !important;
    width: inherit;
    max-height: 230px;
    object-fit: cover;
}
.img-text-inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 15px;
    text-align: center;
    color: #fff;
}
.img-text-inner h3{
    font: 18px/normal Proxima-Nova;
    letter-spacing: 0;
    margin-bottom: 4px;
    font-weight: bold;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.img-text-inner p{
    font: 12px/normal Proxima-Nova;
    letter-spacing: 0;
    max-width: 300px;
    margin: 0 auto 4px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6,
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
    left: 10%;
    background-color: #000 !important;
    background-image: none !important;
    height: 50px;
    width: 50px;
    position: absolute;
    opacity: .7 !important;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6:hover,
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6:focus,
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF:hover,
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF:focus{
    opacity: 1 !important;
}
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6:before,
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6:after{
    width: 15px;
    left: 6px !important;
}
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v span {
    display: none !important;
}
.coverflow__arrow__IDi7v.coverflow__left__PhMc6:hover:before,
.coverflow__arrow__IDi7v.coverflow__left__PhMc6:hover:after {
    left: 6px !important;
}
/* #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v:hover span {
    width: 26px;
} */
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF{
    left: unset;
    right: 110px;
}
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF:before,
#coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF:after{
    width: 15px;
    right: 6px !important;
}
.coverflow__arrow__IDi7v.coverflow__right__1tzAF:hover:before,
.coverflow__arrow__IDi7v.coverflow__right__1tzAF:hover:after{
    right: 6px !important;
}
.co-work-btn button.btn {
    border-radius: 2px;
    padding: 9px 35px;
    font: 18px/normal Proxima-Nova;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdbf14 !important;
    color: #FFFFFF !important;
}





.co-workspace-inner .slick-slide img {
    height: 230px;
    object-fit: cover;
    width: 100%;
}
.co-workspace-inner .slick-track {
    padding: 6em 0;
}
.co-workspace-inner .slick-slide{
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
}
.co-workspace-inner .slick-slide:not(.slick-active) {
    visibility: hidden;
    opacity: 0;
}
.co-workspace-inner .slick-slide .custom-slide{
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
}
.co-workspace-inner .slick-slide.slick-active:not(.slick-current) .custom-slide{
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: .3;
}
.co-workspace-inner .slick-center.slick-current .custom-slide{
    transform: scaleX(1.7) scaleY(1.7) translateY(0%);
    -webkit-transform: scaleX(1.7) scaleY(1.7) translateY(0%);
    -moz-transform: scaleX(1.7) scaleY(1.7) translateY(0%);
    -ms-transform: scaleX(1.7) scaleY(1.7) translateY(0%);
    -o-transform: scaleX(1.7) scaleY(1.7) translateY(0%);
    z-index: 9;
    position: relative;
}
.co-workspace-inner .slick-center.slick-current .custom-slide img{
    object-fit: cover;
}
.co-workspace-inner .slick-slide img{
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
}
.co-workspace-inner .custom-slide.last-second.disabled {
    display: none !important;
}
.co-workspace-inner .slick-dots li button{
    height: 8px !important;
    width: 8px !important;
    background-color: #32343D !important;
    opacity: .4;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.co-workspace-inner .slick-dots li button::before{
    display: none;
}
.co-workspace-inner .slick-dots li{
    width: 15px !important;
}
.co-workspace-inner .slick-dots li.slick-active button{
    background-color: #FDBF14 !important;
    opacity: 1 !important;
    box-shadow: 0 0 0 .2rem rgba(253, 191, 20, .25);
}
.co-workspace-inner .slick-arrow {
    height: 50px !important;
    width: 50px !important;
    background-color: #000 !important;
    border-radius: 50% !important;
    color: #fff !important;
    opacity: 1 !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
}
.co-workspace-inner button.slick-arrow.slick-next:before {
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    opacity: 1;
    padding-left: 3px;
}
.co-workspace-inner button.slick-arrow.slick-prev:before{
    content: '\f104';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    opacity: 1;
}
.co-workspace-inner .slick-prev {
    left: 150px !important;
    z-index: 99;
}
.co-workspace-inner .slick-next {
    right: 150px !important;
    z-index: 99;
}
.co-workspace-inner button.slick-arrow.slick-next.slick-disabled {
    display: none !important;
}
.co-workspace-inner button.slick-arrow.slick-prev.slick-disabled {
    display: none !important;
}

/* End Co-workspace */

/* Reqest A Tour */
.headingTop {
    background: #10152C;
}
.headingTop h1{
    font: 45px/65px Proxima-Nova;
    letter-spacing: 0;
    color: #FFFFFF;
    font-weight: 600;
}
.reqest-tour .card-body{
    font-family: Proxima-Nova;
}
.reqest-tour .card {
    border-color: #707070;
    border-radius: 1px;
    padding: .2em 20px 1.6em;
}
.request-heading h3 {
    font: 28px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #FDBF14;
    padding-bottom: 10px;
    border-bottom: 1px solid #707070;
    margin-bottom: .7em;
    font-weight: 600;
}
.request-heading p {
    font: 18px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
}
.request-heading {
    margin-bottom: 2em;
    margin-top: 2em;
}
.reqest-tour .form-group{
    margin-bottom: 1.5rem;
}
.reqest-tour .form-group label{
    font: 18px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    margin-bottom: .8em;
}
.reqest-tour .form-group input.form-control,
.reqest-tour .form-group select.form-control {
    border-radius: 2px;
    border-color: #0D132C;
    height: 34px;
    min-height: 34px;
    font-size: 14px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.reqest-tour .form-group textarea {
    border-radius: 2px;
    font-size: 14px;
    border-color: #0D132C;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.reqest-tour .form-group .form-control:focus,
.reqest-tour .form-group textarea.form-control:focus {
    border-color: #707070 !important;
    box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, .25);
}
.reqest-tour .form-group input[type="number"]::-webkit-inner-spin-button, 
.reqest-tour .form-group input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
.reqest-tour .form-group button.btn{
    background-color: #FDBF14;
    padding: 7px 32px;
    width: 100%;
    border-color: #fdbf14 !important;
    max-width: 200px;
    color: #fff;
    font-size: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.reqest-tour .form-group button.btn:hover,
.reqest-tour .form-group button.btn:focus,
.reqest-tour .form-group button.btn:active{
    color:#fff;
}
.thankyou .MuiDialog-paperWidthSm {
    text-align: center;
    font-family: Proxima-Nova !important;
}
.thankyou .MuiDialog-paperWidthSm p {
    font-size: 16px;
    font-family: Proxima-Nova !important;
    color: #000;
    margin-bottom: 4px;
}
.thankyou .MuiDialogActions-root {
    text-align: center;
    justify-content: center;
}
.thankyou .MuiDialogActions-root button.MuiButtonBase-root {
    background-color: #FDBF14;
    color: #fff;
    margin-bottom: 10px;
}
/* End Reqest A Tour */


/* The Hub */
.thehub-bgslide{
    background-image: url('https://thebulb.africa/Images/background/the-hub-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 500px;
}
.hub-title {
    height: inherit;
    min-height: inherit;
}
.hub-title h1{
    font-weight: 900;
    font: 70px/1 Proxima-Nova;
    letter-spacing: -3px;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    margin-bottom: 23px;
}
.hub-title button.btn {
    font: 20px/22px Proxima-Nova;
    color: #fff;
    background-color: #FDBF14;
    border-color: #FDBF14;
    padding: 10px 34px;
}
.hub-title button.btn i.fa {
    font-size: 15px;
}
.heading-hub{
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 1em;
}
.heading-hub h4{
    font: 30px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    font-weight: 600;
    margin-bottom: 1.5rem;
}
.hub-whatdo-inner{
    margin-bottom: 2.5em;
}
.hub-whatdo-inner .hub-whatdoContent{
    bottom: 0;
    padding: 15px;
}
.hub-whatdo-inner .hub-whatdoImg img {
    border-radius: 10px;
    box-shadow: 0px 0px 12px rgba(0,0,0,.15);
}
.hub-whatdoContent h5 {
    color: #fff;
    font: 24px/30px Proxima-Nova;
    font-weight: 600;
    text-shadow: 0px 3px 6px #00000029;
    margin-bottom: 5px;
}
.hub-whatdoContent p {
    font: 16px/1.3 Rubik;
    color: #FFFFFF;
    font-weight: 300;
    margin-bottom: 8px;
    min-height: 100px;
}
.hub-whatdoContent a{
    color: #FDBF14;
    font: 16px/normal Proxima-Nova;
    text-decoration: none;
}
.hub-whatdoContent a i{
    font-size: 13px;
}
.events-inner {
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1.5em;
}
.events-inner .eventsContent {
    display: inline-block;
    width: 100%;
    padding: 15px;
    font-family: Proxima-Nova;
    color: #10152C;
}
.events-inner .eventsContent .date {
    margin-bottom: 7px;
    font-size: 16px;
}
.events-inner .eventsContent h5 {
    font-size: 25px;
    font-weight: 600;
}
.events-inner .eventsContent .place {
    font-size: 16px;
    color: rgba(16, 21, 44, 0.56);
}

.ourStroyslider .image-gallery-image {
    min-height: 450px;
    max-height: 500px;
    border-radius: 4px;
    overflow: hidden;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.ourStroyslider .image-gallery-image{
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.ourStroyslider .image-gallery-slides{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3) !important;
}
.ourStroyslider .image-gallery-bullets {
    bottom: 0px;
}
.ourStroyslider .image-gallery-slide .image-gallery-description{
    position: static !important;
    background-color: #FDBF14 !important;
    bottom: unset !important;
    left: 0;
    line-height: normal !important;
    padding: 40px 60px;
    height: inherit;
    flex: 0 0 50%;
    max-width: 50%;
}
.ourStroyslider .storyContent {
    height: 100%;
}
.ourStroyslider .image-gallery-image img{
    flex: 0 0 50%;
    max-width: 50%;
}
.ourStroyslider .image-gallery-image img{
    height: 100%;
    object-fit: cover;
}
.ourStroyslider .storyContent img{
    margin-bottom: 1.5em;
    max-height: 65px !important;
    width: auto;
}
.ourStroyslider .storyContent h4{
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
}
.ourStroyslider .storyContent p.desgnation {
    font-size: 15px;
}
.ourStroyslider .storyContent p{
    font: 16px/1.5 Rubik;
    color: #fff;
}
.ourStroyslider .image-gallery-bullets{
    position: relative;
    margin-top: 30px;
    margin-bottom: 5px;
}
.ourStroyslider .image-gallery-bullets .image-gallery-bullet {
    height: 10px;
    width: 10px;
    background-color: #32343D;
    border-radius: 50px !important;
    line-height: normal !important;
    border: none !important;
    margin: 0 5px;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
    padding: 0;
    box-shadow: none !important;
    opacity: .5;
}
.ourStroyslider .image-gallery-bullets .image-gallery-bullet.active {
    background-color: #FDBF14;
    box-shadow: 0 0 0 0.2rem rgba(253, 191, 20, .25) !important;
    opacity: 1;
}

/* End The Hub */


/* NEWS FEED */
.newsfeed-inner ul.newsfeed-tabs {
    align-items: center;
    /* background-color: #10152C08; */
    justify-content: center;
    margin-bottom: 2em;
    margin-top: 5em;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.newsfeed-inner ul.newsfeed-tabs li.nav-item .nav-link{
    font: 20px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    text-transform: capitalize;
    padding: .6rem 2rem;
    cursor: pointer;
}
.newsfeed-inner ul.newsfeed-tabs li.nav-item .nav-link:hover{
    color: #FDBF14;
}
.newsfeed-inner ul.newsfeed-tabs li.nav-item .nav-link.active{
    color: #FDBF14;
    font-weight: bold;
    border-bottom: 4px solid #FDBF14;
}

.feed-section {
    margin-bottom: 1.8em;
    font-family: Proxima-Nova;
    letter-spacing: 0;
    color: #10152C !important;
    display: block;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
a.feed-section:hover h3 {
    color: #fdbf14;
}
.feed-section .feedImg {
    margin-bottom: 12px;
}
.feed-section .feedImg img {
    border-radius: 4px;
    box-shadow: 0px 3px 3px #00000021;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    max-height: 260px;
    width: 100%;
    height: 260px;
    object-fit: cover;
}
.feed-section .feedContent {
    display: inline-block;
    width: 100%;
}
.feed-section .feedContent h3 {
    font-size: 25px;
    font-weight: bold;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.feed-section .feedContent p{
    font-size: 16px;
    margin-bottom: 0;
}
.feed-section .feedContent p.date{
    font-size: 14px;
    padding-top: 5px;
}
.feed-section .feedContent p.date span {
    padding-left: 4px;
    letter-spacing: .3px;
    color: #FDBF14;
}
.tags-filter-inner{
    list-style: none;
}
.tags-filter-inner li:not(:last-child){
    margin-right: 1em;
}
.tags-filter-inner li {
    margin-bottom: 12px;
}
.tags-filter-inner li span {
    padding: 2px 36px;
    border: 1px solid  rgba(16, 21, 44, 0.4);
    border-radius: 30px;
    font: 16px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    cursor: pointer;
    text-decoration: none;
    display: block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.tags-filter-inner li span.active-tag {
    background-color: rgba(253, 191, 20, 0.16);
}
.tags-filter-inner li span:hover{
    background-color: rgba(253, 191, 20, 0.16);
}

/* END NEWS FEED */


/* SINGLE POST */
h1.post-title {
    font-weight: bold;
    font-size: 40px;
    letter-spacing: -.5px;
}
p.post-date {
    font-size: 14px;
}
.post-date span.post-category {
    color: #FDBF14;
    padding-left: 3px;
}
.post-content p {
    line-height: 1.6;
}
.share-post p{
    font-size: 20px;
}
.share-post li a{
    color: #10152C;
}
.share-post li a:hover,
.share-post li a:focus {
    color: #FDBF14 !important;
}
.singlepost-banner img {
    max-height: 400px;
    object-fit: cover;
    width: 100%;
}
.image-gallery-thumbnail img {
    height: 90px;
    object-fit: cover;
}
.image-gallery-slide img {
    width: 100%;
    height: 360px;
    object-fit: cover;
}
/* END SINGLE POST */


/* SINGLE Gallery Page */
.gallery-small .image-gallery-slide-wrapper span button{
    outline: none;
}
.gallery-small .image-gallery-slide-wrapper span button:before{
    color: #10152C;
    text-shadow: none;
    font-size: 25px;
    font-weight: 600;
}
.gallery-small .image-gallery-slide-wrapper span .image-gallery-left-nav {
    left: -60px;
}
.gallery-small .image-gallery-slide-wrapper span .image-gallery-right-nav{
    right: -60px;
}
.gallery-small .image-gallery-thumbnail.active{
    border-color: #fdbf14;
    border-width: 2px;
    box-shadow: 0 0 0 .2rem rgba(253, 191, 20, 0.25);
}
.gallery-small .image-gallery-slide img, 
.gallery-small .image-gallery-slide,
.gallery-small .image-gallery-image{
    border-radius: 5px;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.gallery-small .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail{
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.gallery-small .image-gallery-thumbnails .image-gallery-thumbnails-container{
    text-align: left;
}
.close-galleryTab button.btn {
    border: 2px solid #10152C;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    font-size: 13px;
    margin-left: auto;
    margin-bottom: 1em;
    top: 0;
    right: 0;
    position: absolute;
}
.gallery-images-count p {
    font: 20px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
}
.gallery-inner-dtl h3 {
    font: 25px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    font-weight: 600;
    margin-bottom: 6px;
}
.gallery-inner-dtl .date,
.gallery-inner-dtl .place {
    font-size: 14px;
    letter-spacing: 0;
    color: #10152C;
    display: block;
    margin-bottom: 6px;
}
.gallery-inner-dtl p{
    color: #10152C;
}
.gallery-small .image-gallery-index span.image-gallery-index-current {
    min-width: 20px;
    display: inline-block;
}
.gallery-small .image-gallery-index {
    right: -39.9%;
    top: 54px;
    text-align: center;
    background: transparent;
    color: #10152C;
    font-size: 20px;
    padding: 0;
    min-width: 52px;
}
/* END single gallery page */


/* The Hub Calendar Page */
.calendar-filter label {
    font: 18px/30px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
}
.calendar-filter .dropdown button.btn {
    min-width: 146px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 2px;
    border-color: #FDBF14;
    background-color: transparent !important;
    color: #FDBF14 !important;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 12px;
}
.calendar-filter .dropdown button.btn:after {
    display: none;
}
.calendar-filter .dropdown .dropdown-menu {
    border-color: #fdbf14;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
    min-width: 145px;
}
.calendar-filter .dropdown .dropdown-menu li.dropdown-item {
    color: #fdbf14;
    padding: 3px 14px;
    font-size: 16px;
    outline: none;
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
}
.calendar-filter .dropdown .dropdown-menu li.dropdown-item.active, 
.calendar-filter .dropdown .dropdown-menu li.dropdown-item:active{
    background-color: #FDBF14;
    color: #000;
}
.calendar-filter .past-events button.btn{
    min-width: 143px;
    border-color: #FDBF14;
    color: #FDBF14 !important;
    border-width: 2px;
    background-color: transparent !important;
    padding: 4px 12px;
}
.calendar-filter .bygrid button.btn {
    padding: 6px 12px;
    outline: none !important;
    box-shadow: none;
}
.calendar-filter .bygrid button.btn.active{
    background-color: #eee;
}
.grid-events-inner .card {
    border-color: #f3f3f3;
    background-color: #fff;
    transition: all .4s ease;
    box-shadow: 0px 0px 8px rgba(0,0,0,.08);
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -o-transition: all .4s ease;
}
.grid-events-inner .card:hover{
    box-shadow: 0px 0px 20px rgba(0,0,0,.15);
}
.grid-events-inner .card .events-photo {
    max-width: 34%;
    flex: 0 0 34%;
}
.grid-events-inner .card .events-photo img {
    max-height: 280px;
    height: 280px;
    border-radius: 3px 0 0 3px;
    width: 100%;
    object-fit: cover;
}
.grid-events-inner .card .events-desc {
    width: 100%;
    padding: 25px 35px;
}
.grid-events-inner .card .events-desc{
    color: #10152C;
}
.grid-events-inner .events-desc .event-title, .grid-events-inner .events-desc .event-title a {
    font: Bold 25px/1.5 Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
    text-decoration: none !important;
}
.grid-events-inner .events-desc p.event-des {
    margin-top: 4px;
    margin-bottom: 7px;
}
.grid-events-inner .events-dateTime{
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 4px;
}
.grid-events-inner .events-place {
    font-size: 14px;
    padding-bottom: 4px;
}
.register-event button.btn {
    min-width: 140px;
    border-width: 2px;
    color: #FDBF14;
    border-color: #FDBF14;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.register-event button.btn:hover,
.register-event button.btn:focus,
.register-event button.btn:active,
.register-event button.btn.active{
    color: #fff;
    background-color: #fdbf14;
}
.calendar-tab .rbc-btn-group:first-child {
    flex: 0 0 130px;
    max-width: 130px;
}
.calendar-tab .rbc-toolbar {
    justify-content: unset;
}
.calendar-tab .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 0;
    min-width: 140px;
    text-align: center;
}
.calendar-tab .rbc-btn-group:first-child > button:first-child:not(:last-child){
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.calendar-tab .rbc-btn-group:first-child button:last-child {
    position: relative;
    left: 128px;
    border: none;
    background: transparent !important;
    outline: none;
    box-shadow: none;
    font-size: 14px;
}
.calendar-tab .rbc-btn-group:first-child button:nth-child(2) {
    outline: none;
    font-size: 14px;
    box-shadow: none;
    border: none;
    background: transparent !important;
    margin-left: 15px;
}
.calendar-tab .rbc-btn-group:last-child{
    margin-left: auto;
}
.calendar-tab .rbc-calendar{
    height: auto !important;
    min-height: 550px;
}
.calendar-tab {
    position: relative;
}
.calendar-tab .eventpopup {
    position: absolute;
    background-color: #fff;
    top: 207px;
    z-index: 99;
    right: 320px;
    padding: 22px 25px 20px 36px;
    box-shadow: 0px 0px 8px rgba(0,0,0,.16);
    border-radius: 4px;
    max-width: 480px;
    width: 521px;
}
.eventpopup h1 {
    position: relative;
    font-size: 24px;
    font-weight: bold;
}
.eventpopup h1:before {
    content: '';
    position: absolute;
    left: -20px;
    top: 8px;
    height: 12px;
    width: 12px;
    background-color: #FDBF14;
    border-radius: 4px;
}
.event-location h5 {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    margin-top: 6px;
    display: inline-block;
    width: 100%;
}
.eventpopup p.event_date {
    font-size: 14px;
}
.event-location i.fa-map-marker {
    left: -20px;
    top: 9px;
    font-size: 13px;
    color: #fdbf14;
}
.event-location p {
    font-size: 14px;
}
.click-register h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}
.click-register a {
    color: #3797F3;
    font-size: 14px;
    line-height: normal;
}
/* End The Hub Calendar Page */

/* Live page */
.live-tab {
    /* background-image: url('../img/magistrale-EPFL-2018-large.png'); */
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    margin: 0 -100px;
    position: relative;
    min-height: 450px;
    overflow: hidden;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.max-width-95 {
    max-width: calc(100% - 100px) !important;
}
.live-tab .live-video-inner {
    background-color: #10152C;
    height: 100%;
    min-height: inherit;
    color: #fff;
    padding: 30px;
}
.video-time {
    background: #6F6F6FA6 0% 0% no-repeat padding-box;
    border-radius: 2px;
    display: inline-block;
    padding: 5px 8px;
    font: 14px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.live-tab .live-video-inner h2 {
    font: 30px/1.4 Proxima-Nova;
    letter-spacing: 0;
    color: #FFFFFF;
    margin-bottom: .71em;
    margin-top: 15px;
}
.live-tab .live-video-inner button.btn {
    background: #FDBF14 !important;
    border-radius: 22px;
    padding: 9px 15px;
}
.rel-live-inner a{
    text-decoration: none;
}
.live-video-dtl h4{
    font-size: 25px;
    line-height: 1.1;
    text-decoration: none;
    font-weight: bold;
    color: #10152C;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.live-video-dtl h4:hover{
    color: #FDBF14;
}
.live-video-dtl p.live-date{
    font-size: 14px;
    padding-top: 5px;
    color: #10152C;
}
.live-video-dtl p.live-date span{
    color: #FDBF14;
    font-weight: 600;
    padding-left: 5px;
}
.live-thumb .video-time {
    top: 10px;
    left: 10px;
    background: rgba(16, 21, 44, 0.50);
}

#videoYoutube button.close {
    right: -30px;
    color: #fff;
    font-size: 45px;
    opacity: 1;
    top: -10px;
    font-weight: 400;
    font-family: Proxima-Nova !important;
    box-shadow: none !important;
    text-shadow: none !important;
    outline: none !important;
    transition: all 0.4s ease;
}
.modal.show .modal-dialog{
    pointer-events: all;
}
#videoYoutube button.close:hover {
    color: #fdbf14;
}
/* End Live page */


/* Startup page */
.startup{
    background-image: url('https://thebulb.africa/Images/background/bg-startup.jpg');
    margin-top: -1em;
}
.startup-inner h1 {
    font: 60px/65px Proxima-Nova;
    font-weight: 600;
}
.startup-inner p{
    font: 24px/34px Proxima-Nova;
}
.startup-btn button.btn {
    padding: 12px 20px;
    font-weight: 600;
    min-width: 240px;
    border: none;
    font: 20px/22px Proxima-Nova;
    position: relative;
    z-index: 1;
    box-shadow: none;
}
.startup-btn button.btn:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(1);
    -webkit-transform: translate(-50%, -50%) scaleX(1);
    -moz-transform: translate(-50%, -50%) scaleX(1);
    -ms-transform: translate(-50%, -50%) scaleX(1);
    -o-transform: translate(-50%, -50%) scaleX(1);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    border: 2px solid #fdbf14;
    z-index: -1;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.startup-btn button.btn:hover:before{
    transform: translate(-50%, -50%) scaleX(1.2);
    -webkit-transform: translate(-50%, -50%) scaleX(1.2);
    -moz-transform: translate(-50%, -50%) scaleX(1.2);
    -ms-transform: translate(-50%, -50%) scaleX(1.2);
    -o-transform: translate(-50%, -50%) scaleX(1.2);
}
.btn-cus-warning:before{
    color: #fff;
    background-color: #fdbf14;
}
.btn-cus-outline-warning{
    color: #fdbf14 !important;
}
/* End startup page */



/* careers page */
.bg-careers{
    background-image: url('https://thebulb.africa/Images/background/the-hub-bg.jpg');
}
.search-job i{
    position: absolute;
    top: 50%;
    left: 14px;
    pointer-events: none;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    opacity: 0.4;
}
.search-job input{
    padding-left: 40px;
}
.careers-inner .form-group .form-control{
    min-height: 55px;
    border: none;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    font: 18px/22px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
}
.searched-careers{
    margin-top: -70px;
}
.searched-careers-inner{
    background-color: #fff;
    min-height: 250px;
}
.job-found-title{
    font: 30px/22px Proxima-Nova;
    letter-spacing: 0;
    color: #10152C;
}
.job-found-title span{
    font: 24px/22px Proxima-Nova;
    color: #9D9D9D;
}
.searched-careers-inner .founded-jobs {
    flex: 0 0 47%;
    max-width: 47%;
    padding: 20px 15px;
    margin-bottom: 2em;
    border: 1px solid rgba(157, 157, 157, .2);
}
.searched-careers-inner .founded-jobs:nth-child(2n+2){
    margin-right: 6%;
}
.searched-careers-inner .founded-jobs .founded-jobs-inner {
    width: 100%;
}
.founded-jobs .founded-jobs-inner h4 {
    font-size: 20px;
    color: #10152C;
    margin-bottom: 13px;
    text-transform: uppercase;
}
.founded-jobs-inner .founded-jobs-img {
    background-color: rgb(245, 245, 245);
    height: 65px;
    width: 65px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.founded-jobs-inner .founded-jobs-img img{
    height: inherit;
    width: inherit;
    object-fit: inherit;
    border-radius: inherit;
}
.founded-jobs-inner .founded-jobs-dtl {
    flex-grow: 1;
}
.founded-jobs-inner .founded-jobs-dtl .job-title {
    flex-grow: 1;
    margin-right: 15px;
}
.founded-jobs-inner .founded-jobs-dtl .job-title h5 {
    font-size: 18px;
    color: #10152C;
}
.founded-jobs-inner .founded-jobs-dtl .job-title p {
    font-size: 14px;
    color: rgba(16, 21, 44, 0.54);
    margin-bottom: 5px;
}
.founded-jobs-inner .founded-jobs-dtl .job-title p i {
    padding-right: 4px;
    color: #6d6d6d;
}
.founded-jobs-inner .right-icon a {
    background-color: #FDBF14;
    box-shadow: 0px 3px 6px #00000029;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}
.founded-jobs-inner .founded-jobs-dtl .right-icon a {
    background-color: #FDBF14;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    line-height: normal;
    justify-content: center;
    color: #fff;
    border-radius: 50px;
    text-decoration: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.founded-jobs-inner .founded-jobs-dtl .right-icon a i {
    font-weight: 600;
    font-size: 20px;
}
.pagination-main .page-item .page-link {
    background-color: rgba(141, 141, 141, .2);
    color: #000;
    border-color: rgba(141, 141, 141, .2);
    margin: 0 5px;
    border:none;
    border-radius: 3px;
    padding: 8px 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
}
.pagination-main .page-item.disabled{
    display: none;
}
.pagination-main .page-item .page-link:focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 232, 232, .55);
}
.pagination-main .page-item.active .page-link {
    background-color: #FDBF14;
    border-color: #FDBF14;
    color: #fff;
}
.pagination-main .page-item.next-prev-btn .page-link{
    background-color: transparent;
    border: none;
}
.pagination-main .page-item.next-prev-btn i{
    background-color: #fdbf14;
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    top: -1px;
}
.pagination-main .page-item:hover .page-link {
    background-color: #fdbf14;
    color: #fff;
}
.pagination-main .page-item.next-prev-btn:hover i{
    background-color: #fff;
    color: #fdbf14; 
}
.min-h-350{
    min-height: 350px;
}
.careers-expended{
    background-color:#F6F6F6;
}
.careers-expended-left{
    top: 77px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    padding: 20px 20px 25px;
}
.careers-expended-left .job-image {
    background-color: rgba(245, 245, 245, .5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    height: 65px;
    width: 65px;
    margin-bottom: 15px;
}
.careers-expended-left .job-image  img{
    height: auto;
    width: inherit;
}
.careers-expended-left h4.job-title {
    font-size: 20px;
    padding-bottom: 3px;
    color: #10152C;
    text-transform: uppercase;
}
.careers-expended-left p {
    font-size: 16px;
    color: #989AA4;
    margin-bottom: 8px;
}
h4.job-desc-title {
    color: #10152C;
    font-weight: 600;
    margin-bottom: .51em;
}
.careers-expended-left p i{
    margin-right: 4px;
    min-width: 20px;
}
.apply-btn .btn {
    min-width: 190px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.careers-expended-desc {
    padding: 25px;
    border-radius: 2px;
}
.careers-expended-desc ul {
    padding-left: 0px;
    list-style: none;
}
.careers-expended-desc ul li{
    position: relative;
    padding-left: 18px;
    padding-bottom: 6px;
}
.careers-expended-desc ul li:before{
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    height: 6px;
    width: 6px;
    background-color: #FDBF14;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.applyjobform{
    padding-left: 17px;
}
.applyjobform .modal-dialog{
    max-width: 1140px;
    margin: 4.75rem auto;
}
.applyjobform button.close {
    right: -40px;
    color: #fff;
    text-shadow: none;
    opacity: 1;
    font-size: 48px;
    font-weight: 200;
    top: -45px;
}
.applyjobform-inner {
    padding: 15px;
}
.applyjobform-inner h2{
    font-size: 30px;
    color: #10152C;
    font-weight: 600;
}
.applyjobform-inner .request-heading h3{
    font-size: 24px;
    font-weight: 500;
}
.applyjobform-inner .form-group label {
    font-size: 18px;
    color: #10152C;
    margin-bottom: .8em;
}
.applyjobform-inner .form-group input.form-control {
    border-radius: 2px;
    border-color: #0D132C;
    height: 34px;
    min-height: 34px;
    font-size: 14px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.applyjobform-inner .form-group textarea.form-control{
    border-radius: 2px;
    font-size: 14px;
    border-color: #0D132C;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.applyjobform-inner .form-group .form-control:focus{
    border-color: #707070 !important;
    box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, .25);
}
.applyjobform-inner .form-group input[type=number]::-webkit-inner-spin-button{
    display: none;
}
.applyjobform-inner .profile label{
    color: #919191;
    font-size: 20px;
}
.applyjobform-inner .profile input[type=file]{
    outline: none;
    cursor: pointer;
}
.applyjobform-inner .profile small{
    color: #919191;
    font-size: 14px;
}
.webpresence input.form-control{
    border: 2px solid #C5C6CD !important;
    height: 49px !important;
}
.applyjobform-inner .form-group button.btn{
    min-width: 190px;
    color: #fff;
}
/* End careers page */

/* submit-idea page */

.submit-ideaForm .form-group small{
    color: #919191;
}
.checkbox-c input[type="checkbox"]{
    position: relative;
    margin-top: 3px;
    outline: none;
}
.checkbox-c input[type="checkbox"]::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    background: #FFF;
    box-shadow: 0px 3px 6px #00000005;
    border: 1px solid #B1B1B1;
    height: 18px;
    width: 18px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    cursor: pointer;
}
.checkbox-c input[type="checkbox"]::after{
    content: '';
    position: absolute;
    cursor: pointer;
    top: 6px;
    left: 9px;
    height: 13px;
    width: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: translate(-50%, -50%) rotateZ(13deg) scale(2);
    -webkit-transform: translate(-50%, -50%) rotateZ(13deg) scale(2);
    -moz-transform: translate(-50%, -50%) rotateZ(13deg) scale(2);
    -ms-transform: translate(-50%, -50%) rotateZ(13deg) scale(2);
    -o-transform: translate(-50%, -50%) rotateZ(13deg) scale(2);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    visibility: hidden;
    opacity: 0;
}
.checkbox-c input[type="checkbox"]:checked::before{
    background-color: #fdbf14;
    border-color: #fdbf14;
}
.checkbox-c input[type="checkbox"]:checked::after{
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(45deg) scale(1);
    -webkit-transform: translate(-50%, -50%) rotateZ(45deg) scale(1);
    -moz-transform: translate(-50%, -50%) rotateZ(45deg) scale(1);
    -ms-transform: translate(-50%, -50%) rotateZ(45deg) scale(1);
    -o-transform: translate(-50%, -50%) rotateZ(45deg) scale(1);
}
.submit-ideaForm .form-group label.checkbox-c{
    font-size: 18px;
    color: #10152C;
}
.submit-ideaForm .form-group label.checkbox-c a{
    color: #fdbf14;
    text-decoration: none;
}
input[type="file"]::-webkit-file-upload-button{
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #808080AB 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070;
    padding: 4px 25px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font: 18px/normal Proxima-Nova;
    letter-spacing: 0;
    color: #000000;
}
/* End submit-idea page */


/* Become A Developer page */
.become-developer-slide{
    background-image: url('https://thebulb.africa/Images/background/bg-become-developer.jpg');
    position: relative;
}
.become-developer-slide:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.56);
}
.become-developer-slide h1{
    font-size: 45px;
}
.become-developer-slide p{
    font-size: 20px;
}
.course-inner{
    margin-bottom: 1.8em;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.course-inner .course-image{
    position: relative;
    z-index: 1;
}
.course-inner .course-image::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(16, 21, 44, .5);
}
.course-inner .course-headings{
    bottom: 0;
    padding: 30px 25px;
    color: #fff;
    width: 100%;
    z-index: 1;
    transition: all 0.4s ease;
    height: 150px;
}
.course-inner:hover .course-headings {
    height: 100%;
    background-color: #10152C;
}
.course-inner:hover .course-headings ul{
    visibility: visible;
    opacity: 1;
    pointer-events: all;
}
.course-inner .course-headings h4{
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0px 3px 6px #00000029;
}
.course-inner .course-headings p{
    color: #FDBF14;
}
.course-inner .course-headings ul {
    padding-left: 17px;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
}
.course-inner .course-headings ul li{
    margin-bottom: 5px;
}
.course-inner .course-headings button.btn{
    color: #fff;
    padding: 4px 40px;
    margin-top: 20px;
}

/* END Become A Developer page */


/* Training page */

.training-banner{
    background-image: url('https://thebulb.africa/Images/individual/bg-traning.jpg');
}
.training-banner h1{
    font-size: 45px;
}
.training-banner p{
    font-size: 20px;
    max-width: 680px;
}
.training ul.nav-tabs li.nav-item:not(:last-child) {
    margin-right: 20px;
}
.training ul.nav-tabs li.nav-item .nav-link {
    border: none;
    position: relative;
    color: #10152C;
    font-size: 18px;
    padding: 0px 0px 8px;
    margin-bottom: 10px;
}
.training ul.nav-tabs li.nav-item .nav-link:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0%;
    background-color: #FDBF14;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.training ul.nav-tabs li.nav-item .nav-link.active{
    color: #FDBF14;
}
.training ul.nav-tabs li.nav-item .nav-link.active:before{
    width: 100%;
    visibility: visible;
    opacity: 1;
}
/* End Training page */


/* Hire Developer page */

.section-heading {
    font-size: 30px;
    color: #10152C;
    font-weight: 600;
}
.whychoose {
    background-color: #DAE1FF29;
}
.whychoose-inner {
    padding: 25px 20px 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    color: #10152C;
    margin-bottom: 1.5em;
}
.whychoose-inner h4 {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 6px;
    padding-top: 5px;
}
.whychoose-inner p {
    font-size: 18px;
    line-height: 1.6;
}
.hiredev-form label {
    color: #10152C;
    font-size: 18px;
}
.hiredev-form .form-control {
    border: 0.5px solid #0D132C;
    border-radius: 2px;
}
.hiredev-form .form-control:focus{
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, 0.16);
}
.hiredev-form input[type=number]::-webkit-inner-spin-button{
    display: none;
}
.hiredev-form .form-group button.btn{
    min-width: 190px;
}
/* End Hire Developer page */


/* End partners width us page */
.bg-partners{
    background-image: url('https://thebulb.africa/Images/background/bg-partner-banner.jpg');
}
.bg-partners h1{
    font-size: 45px;
}
.bg-partners p{
    font-size: 20px;
}
.why-partners p{
    font-size: 18px;
}
.benefits-inner{
    margin-bottom: 1.5em;
}
.benefits-inner .benefits-img img{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.benefits-inner .benefits-content{
    display: inline-block;
    width: 100%;
}
.benefits-inner .benefits-content h4{
    color: #10152C;
    font-size: 24px;
    font-weight: 600;
    margin-top: 8px;
}
.benefits-inner .benefits-content p{
    font-size: 18px;
}
.partner-logo{
    margin-bottom: 1.4em;
}
.partner-logo img {
    height: 47px;
}
.bg-partnership{
    background-image: url('https://thebulb.africa/Images/background/bg-start-partnership.jpg');
}
.partnership-form{
    background-color: #fff;
    padding: 35px 50px 30px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.partnership-form label{
    color: #10152C;
    font-size: 18px;
}
.partnership-form .form-control{
    border: 1px solid #0D132C;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.partnership-form .form-control:focus{
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, 0.16);
}
.partnership-form .form-group button.btn{
    min-width: 190px;
    font-size: 18px;
}
/* End partners width us page */


/* 404 Not Found page */
.notfound{
    background-image: url('https://thebulb.africa/Images/background/404-not-found.jpg');
}
.notjobfound{
    background-image: url('https://thebulb.africa/Images/background/BG.png');
}
.notfound-inner .sademoji{
    height: 35px;
}
.notfound-inner h1{
    font-size: 46px;
    padding-bottom: 5px;
}
.notfound-inner p{
    color: #6C738A;
}
/* End 404 Not Found page */

/* FAQs page */
.faqs-inner .seaction-heading h3 {
    font-size: 30px;
    color: #10152C;
}
.faqs-inner .seaction-heading h5 {
    font-size: 20px;
}
.faqs-inner .seaction-heading h5 a{
    color: #10152C;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}
.faqs-inner .seaction-heading h5 a:hover{
    color: #FDBF14;
}
.faqs-inner .accordion .card {
    border-left: none;
    border-right: none;
    border-radius: 0;
    background: transparent !important;
}
.faqs-inner .accordion .card .card-header {
    border-bottom: none;
    background-color: transparent;
    padding: 0;
}
.faqs-inner .accordion .card .card-body {
    padding: 0;
}
.faqs-inner .accordion .card .card-header                                                                                                 button.btn {
    padding: 0;
}
.faqs-inner .accordion .card .card-header button.btn {
    padding: 0;
}
.faqs-inner .accordion .card .card-header h2 {
    font-size: 19px;
    color: #10152C;
    font-weight: 600;
    cursor: pointer;
}
.faqs-inner .accordion .card .card-header h2 .learn-more {
    color: #5E5F63;
    font-weight: normal;
    font-size: 16px;
    padding-left: 15px;
}
.faqs-inner .accordion .card .card-header h2 .learn-more .viewless{
    display: none;
}
.faqs-inner .accordion .card .card-header h2:not(.collapsed) .learn-more span:first-child{
    display: none;
}
.faqs-inner .accordion .card .card-header h2:not(.collapsed) .learn-more span.viewless{
    display: block;
}
.faqs-inner .accordion .card .card-header h2:not(.collapsed) .learn-more i.fa-plus:before{
    content: "\f068";
}
.faqs-inner .accordion .card .card-header h2 .learn-more i{
    color: #10152C;
    font-weight: 600;
}
.faqs-inner .accordion .card .card-body {
    padding-top: 10px;
    max-width: 65%;
}
.faqs-inner .accordion .card .card-body,
.faqs-inner .accordion .card .card-body p{
    font: 17px/1.5 Rubik;
    color: #10152C;
    margin-bottom: 5px;
}
.faqs-inner .accordion .card {
    padding: 35px 0px;
}
/* End FAQs page */



/* Privacy Policy page */
.section-title{
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 6px;
}
/* End Privacy Policy page */


/* End Privacy Policy page */
.no-event button.btn{
    border-width: 2px;
    color: #10152C;
    margin-bottom: 10px;
}
/* End Privacy Policy page */

/* Resources */
.resources .search{
    max-width: 280px;
    margin-left: auto;
}
.resources .search input[type=search]{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FDBF14;
    min-height: 40px;
    color: #10152C;
    width: 100%;
    padding: 5px 10px 5px 38px;
    outline: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.resources .search input[type=search]:focus{
    box-shadow: 0 0 0 0.2rem rgba(253, 191, 20, 0.25);
}
.resources-tab .newsfeed-inner ul.newsfeed-tabs li.nav-item .nav-link{
    white-space: nowrap;
    font-size: 20px;
    color: #10152C;
    padding: .6rem 2rem;
    cursor: pointer;
}
.resources-tab .newsfeed-inner ul.newsfeed-tabs li.nav-item .nav-link.active{
    color: #FDBF14;
    font-weight: bold;
}
.resources-tab .newsfeed-inner ul.newsfeed-tabs li.nav-item .nav-link:hover {
    color: #FDBF14;
}
.resources .search i.fa-search {
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: .3;
    font-size: 14px;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
  }
/* End Resources */

.middleContent {
    min-height: calc(100vh - 72px - 320px);
}
/* Loader  */
.cusloader {
    position: fixed;
    z-index: 0;
    background-color: rgba(0,0,0,.7);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
}
.cusloader .MuiCircularProgress-colorPrimary {
    color: #fdbf14 !important;
}
.loadercuss{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadercuss .MuiCircularProgress-colorPrimary{
    color: #fdbf14;
}
/* Loader  */
@keyframes upDown{
    0%{
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
    }
    50%{
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
    }
    100%{
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
    }
}
@-webkit-keyframes upDown{
    0% {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
    }
    50%{
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
    }
    100%{
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
    }
}

/* lightupDown */
@keyframes lightupDown{
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 0.5;
    }
    50%{
        opacity: 0.8;
    }
    70%{
        opacity: 0.9;
    }
    80%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.1;
    }
}
@-webkit-keyframes lightupDown{
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 0.5;
    }
    50%{
        opacity: 0.8;
    }
    70%{
        opacity: 0.9;
    }
    80%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.1;
    }
}

@media screen and (max-width: 1200px){
    .applyjobform button.close {
        right: 0px;
    }
}
@media screen and  (min-width:768px) and (max-width: 991px){
    .apply-btn .btn{
        min-width: auto;
    }
}
@media screen and  (min-width:768px){
    .navbar ul.navbar-nav li.nav-item:first-child .nav-link{
        margin-left: 0px;
    }
    .navbar ul.navbar-nav li.nav-item .nav-link {
        position: relative;
    }
    .navbar ul.navbar-nav li.nav-item .nav-link:before {
        content: '';
        position: absolute;
        bottom: -17px;
        left: 0;
        height: 2px;
        width: 0;
        background-color: #fdbf14;
        border-radius: 4px;
        transition: all .5s ease;
        visibility: hidden;
        opacity: 0;
    }
    .navbar ul.navbar-nav li.nav-item .nav-link:hover:before, 
    .navbar ul.navbar-nav li.nav-item .nav-link:focus:before {
        width: 100%;
        visibility: visible;
        opacity: 1;
    }
    .navbar ul.navbar-nav li.nav-item .nav-link.active{
        color: #FDBF14;
    }
    .navbar ul.navbar-nav li.nav-item .nav-link.active:before{
        width: 100%;
        visibility: visible;
        opacity: 1;
    }
    .slider_inner img {
        position: relative;
        top: 20px;
    }
    nav#navTop .dropdown-menu.nav-top-cus {
        position: static !important;
        display: block !important;
        background: none !important;
        transform: none !important;
        float: none !important;
        min-width: unset !important;
        text-align: unset !important;
        border: none !important;
        width: auto !important;
    }
    .sticky-top-200 {
        top: -200px;
        position: relative;
    }
    .webpresence label{
        margin-bottom: 15px;
    }
}
@media screen and  (min-width:992px) and (max-width:1200px){
    .live-tab {
        margin: 0 -20px;
    }
}
@media screen and  (min-width:768px) and (max-width:1199px){
    .ourStroyslider .image-gallery-image img {
        height: inherit;
    }
}
@media screen and  (min-width:768px) and (max-width:991px){
    .slider_inner {
        height: 100%;
        min-height: calc(100vh - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slider-content h1 {
        font-size: 39px;
    }
    .live-tab {
        margin: 0 0px;
    }
}
@media screen and (max-width:799px){
    .co-workspace-inner .custom-slide.last-second.disabled {
        display: inline-block !important;
    }
}
@media screen and (min-width:576px) and (max-width:767px){
    .grid-events-inner .card .events-desc {
        padding: 20px 25px;
    }
    .grid-events-inner .events-desc .event-title, .grid-events-inner .events-desc .event-title a{
        font-size: 20px;
        line-height: normal;
    }
    .grid-events-inner .card{
        align-items: stretch !important;
    }
    .grid-events-inner .card .events-photo img {
        max-height: 100%;
    }
}
@media screen and  (max-width:767px){
    nav.navbar {
        padding: 0 15px !important;
    }
    .navbar-toggler{
        background-color: #ffff;
    } 
    span.navbar-toggler-icon {
        filter: invert(1);
        height: 1em;
        width: 1em;
        -webkit-filter: invert(1);
    }
    .navbar-nav {
        margin-bottom: 15px;
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 2px;
    }
    .navbar-light .navbar-toggler {
        border-color: #888a96;
        padding: 2px 7px;
        border-radius: 1px;
    }
    .slider {
        padding-top: 80px;
    }
    .slider-content h1 {
        font-size: 48px;
        padding-bottom: 0em;
        text-align: center;
    }
    .slider_inner .row{
        position: relative;
    }
    .slider_inner .row .col-md-5 {
        position: static;
        opacity: .8;
    }
    .slider_inner img {
        max-width: 50%;
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
    }
    .navbar ul.navbar-nav li.nav-item:first-child .nav-link{
        padding-top: 15px;
    }
    .navbar ul.navbar-nav li.nav-item .nav-link {
        color: #10152c;
        padding-bottom: 10px;
        padding-top: 0;
    }
    .navbar ul.navbar-nav li.nav-item:last-child .nav-link{
        padding-bottom: 15px;
    }
    .slider_inner {
        padding: 40px 0 1em;
        min-height: calc(100vh - 200px);
        display: flex;
        align-items: center;
    }
    .slider_inner .container,
    .slider_inner .container .row{
        height: 100%;
    }
    .slider-bottom {
        bottom: 40px;
    }
    .contact-second .row {
        flex-direction: column-reverse;
    }
    .co-work-slider .coverflow__container__1P-xE{
        max-width: 100%;
    }
    .ourStroyslider .image-gallery-image{
        max-height: none;
        min-height: auto;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important;
    }
    .storyInner {
        height: auto;
        -ms-flex-direction: column!important;
        flex-direction: column!important;
    }
    .ourStroyslider .image-gallery-slide .image-gallery-description,
    .ourStroyslider .image-gallery-image img{
        flex: auto;
        max-width: 100%;
    }
    .ourStroyslider .image-gallery-slide .image-gallery-description{
        padding: 35px 45px !important;
    }
    .ourStroyslider .image-gallery-image img{
        max-height: 400px;
    }
    .newsfeed-inner ul.newsfeed-tabs {
        flex-wrap: nowrap;
        overflow: auto;
        justify-content: unset;
    }
    .calendar-filter {
        flex-wrap: wrap;
    }
    .calendar-filter .sort-cal {
        margin-bottom: .9rem;
    }
    .grid-events-inner .card .events-desc {
        padding: 20px 18px;
    }
    .live-tab {
        margin: 0;
        min-height: 350px;
    }
    .startup-inner h1 {
        font: 40px/normal Proxima-Nova;
    }
    .searched-careers-inner .founded-jobs {
        max-width: 100%;
        flex: auto;
        margin-right: 0 !important;
    }
    .become-developer-slide{
        text-align: center;
    }
    .become-developer-slide h1 {
        font-size: 36px;
    }
    .become-developer-slide p {
        font-size: 16px;
    }
    .become-developer-slide img {
        margin-right: auto;
        margin-bottom: 1.5em;
    }
    .training-banner h1{
        font-size: 36px;
    }
    .training-banner p{
        font-size: 16px;
    }
    .careers-expended-left {
        text-align: center;
    }
    .careers-expended{
        background: transparent;
    }
    .gallery-small .image-gallery-index {
        top: calc(100% + 80px);
        right: unset;
        left: 0;
    }
    .no-event{
        text-align: center;
    }
}
@media screen and (max-width: 575px){
    nav.navbar {
        background-color: rgba(16, 21, 44, .2);
    }
    .individual-bg-slide, .corporation-bg-slide, .ngo-bg-slide {
        max-width: 100%;
        flex: 100%;
    }
    .slider-content h1 {
        font-size: 34px;
        padding-bottom: .51em;
        line-height: 1.2;
    }
    .slider-hover-inner ul li a {
        text-align: center;
    }
    .slider-bottom .slider-bottom-inner h3 {
        font-size: 18px;
    }
    .slider-bottom-inner.indiarrow + .slider-bottom-inner + .slider-bottom-inner.ngoratearrow h3 {
        color: #fff;
    }
    .slider-bottom-inner.indiarrow + .slider-bottom-inner + .slider-bottom-inner.ngoratearrow img {
        visibility: visible;
        opacity: 1;
    }
    .banner-title{
        font-size: 30px;
        margin-bottom: 0;
    }
    .member-inner {
        max-width: 250px;
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
    .fixed-tab {
        right: 15px;
    }
    footer .copyright {
        padding-top: 3em;
    }
    footer.footer {
        padding: 50px 0px 25px;
    }
    .img-text-inner {
        position: relative;
        padding: 20px 10px;
        background-color: #fff;
        opacity: 1 !important;
    }
    .img-text-inner h3 {
        font-size: 20px !important;
        text-shadow: none;
        color: #000;
        margin-bottom: 10px;
    }
    .img-text-inner p {
        text-shadow: none;
        font-size: 15px;
        color: #777;
        line-height: 1.5;
        max-width: 100%;
    }
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6, #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        display: none;
    }
    .reqest-tour .card {
        border: none;
        padding: 0;
    }
    .reqest-tour .card-body {
        padding: 0 .5rem;
    }
    .request-heading h3 {
        font-weight: 500;
        font-size: 24px;
    }
    .address-place.mr-sm-4 {
        margin-right: 30px;
    }
    .hub-title h1 {
        font-size: 30px;
        letter-spacing: 0;
        line-height: normal;
    }
    .hub-title {
        padding: 0 15px;
        margin-bottom: 20px;
    }
    .hub-whatdo-inner {
        margin-bottom: 2em;
    }
    .events-inner .eventsContent {
        padding: 24px 15px 20px;
    }
    .storyInner .storyContent{
        padding: 30px 20px;
    }
    .storyInner .storyContent img {
        margin-bottom: 2em;
        width: 50px;
    }
    .grid-events-inner .card{
        flex-direction: column !important;
    }
    .grid-events-inner .card .events-photo{
        max-width: 100%;
        flex: 0 0 100%;
    }
    .grid-events-inner .events-desc .event-title, .grid-events-inner .events-desc .event-title a{
        font: Bold 20px/normal Proxima-Nova;
        margin-bottom: .5em !important;
    }
    .calendar-filter {
        align-items: flex-start !important;
    }
    .calendar-filter label{
        font-size: 16px;
    }
    .calendar-filter .dropdown button.btn{
        font-size: 16px;
    }
    .startup-inner h1 {
        font: 36px/normal Proxima-Nova;
    }
    .startup-inner p {
        font: 17px/1.5 Proxima-Nova;
    }
    .startup-btn button.btn {
        padding: 9px 20px !important;
        min-width: 230px;
        margin-bottom: 10px;
    }
    .careers-expended-left {
        text-align: center;
    }
    h4.job-desc-title {
        text-align: center;
    }
    .gallery-small .image-gallery-slide-wrapper span .image-gallery-left-nav{
        left: 0;
    }
    .gallery-small .image-gallery-slide-wrapper span .image-gallery-right-nav{
        right: 0;
    }
    .submit-idea-title .heading-inner h1 {
        font-size: 36px;
        line-height: normal;
    }
    .benefits-inner .benefits-img img{
        width: 100%;
    }
    .why-partners h3.section-heading {
        margin-top: 11px;
        margin-bottom: 12px !important;
    }
    .partnership-form {
        padding: 30px;
    }
    .benefits-inner .benefits-content h4 {
        font-size: 22px;
    }
    .benefits-inner .benefits-content p {
        font-size: 16px;
    }
    .partner-logo img {
        width: 200px;
        height: auto;
    }
    .notfound-inner h1{
        font-size: 27px;
    }
    .notfound{
        background-color: #eaeef1;
    }
    .notfound-inner{
        padding-left: 15px;
        padding-right: 15px;
    }
    .faqs-inner .accordion .card .card-header h2 span{
        display: none !important;
    }
    .faqs-inner .accordion .card .card-body {
        max-width: 90%;
    }
    .headingTop h1 {
        font-size: 36px;
        line-height: normal;
    }
    .resources .search{
        margin-left: unset;
    }
    .custom-slide {
        background-color: #fff;
        box-shadow: 0px 0px 12px rgba(0,0,0,.1);
    }
    .co-workspace-inner  .slick-arrow{
        display: none !important;
    }
    .co-workspace-inner .slick-center.slick-current .custom-slide{
        transform: scale(1.1) translateY(0%);
        -webkit-transform: scale(1.1) translateY(0%);
        -moz-transform: scale(1.1) translateY(0%);
        -ms-transform: scale(1.1) translateY(0%);
        -o-transform: scale(1.1) translateY(0%);
}
    .co-workspace-inner .slick-track {
        padding: 2em 0;
    }
    .live-tab {
        display: flex;
        align-items: flex-end;
    }
    .live-tab .live-video-inner {
        height: 35%;
        min-height: auto;
    }
    .live-tab .live-video-inner h2 {
        font-size: 24px;
        margin-bottom: .81em;
        margin-top: 5px;
    }
    .live-tab .live-video-inner button.btn {
        padding: 7px 33px !important;
        font-size: 14px;
    }
    .live-tab {
        min-height: 480px;
    }
    .max-width-95 {
        max-width: 100% !important;
    }
    #videoYoutube iframe{
        height: 230px;
    }
    #videoYoutube button.close {
        right: 10px;
        top: 0px;
    }
    .calendar-tab .rbc-btn-group:last-child {
        margin-right: auto;
        margin-top: 9px;
        margin-bottom: 9px;
    }
}

@media screen and (max-width: 475px){
    .contact-first .contact-buttons button.btn {
        width: 100%;
    }
    .min-h-350{
        min-height: 180px;
    }
    .founded-jobs-inner .founded-jobs-img {
        height: 50px;
        width: 50px;
        margin-right: 10px;
    }
    .founded-jobs-inner .founded-jobs-dtl .job-title h5 {
        font-size: 16px;
        margin-bottom: 2px;
    }
    .founded-jobs-inner .founded-jobs-dtl .job-title p {
        margin-bottom: 0;
    }
}
@media screen and (max-width: 375px){
    .slider-bottom .slider-bottom-inner h3 {
        font-size: 14px;
    }
    .slider-bottom .slider-bottom-inner img {
        width: 12px;
    }
    .slider-hover-inner h3 {
        font-size: 20px;
        padding-bottom: 0;
    }
    .slider-hover-inner ul{
        padding: 0;
    }
    .slider-content h1 {
        font-size: 28px;
    }
    .whowe-bg{
        background-image: url('https://thebulb.africa/Images/background/bg-mobile-who.jpg');
        position: relative;
        z-index: 1;
        margin-top: 70px;
    }
    .whowe-bg:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.4);
        z-index: -1;
    }
    .team-bg{
        background-image: url('https://thebulb.africa/Images/about/bg-mobile-team.jpg');
    }
    .advisteam-bg{
        background-image: url('https://thebulb.africa/Images/about/bg-mobile-advisory.jpg');
    }
    .startup-inner h1 {
        font: 30px/normal Proxima-Nova;
    }
    .startup-btn button.btn {
        padding: 7px 15px !important;
        font-size: 16px;
        min-width: 190px;
    }
}

