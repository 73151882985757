@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:wght@500&family=Poppins:wght@300;400&display=swap");

@import url("https://fonts.cdnfonts.com/css/clash-display?styles=106287");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@300;400&family=Roboto&display=swap");
/* @import url("/src/fonts//css//satoshi.css"); */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Manrope:wght@200..800&family=Montserrat:wght@500&family=Poppins:wght@300;400&display=swap");


@import "./Assets/css/fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: medium;
  font-style: normal;
}
.tk-proxima-nova {
  font-family: "proxima-nova", sans-serif;
}
.tk-proxima-nova-condensed {
  font-family: "proxima-nova-condensed", sans-serif;
}
.tk-proxima-nova-extra-condensed {
  font-family: "proxima-nova-extra-condensed", sans-serif;
}

*,
*:hover,
*:focus {
  outline: none !important;
  font-family: "proxima-nova", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.show-style{
  list-style-type: disc;
}
input {
  background-color: #ffff;
}

.is_live {
  color: red !important;
}

.animate-circle {
  position: relative;
}
.animate-circle::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 40%;
  width: 5px;
  height: 5px;
  border: 5px solid red;
  border-radius: 30px;
  background-color: red;
  z-index: 10;
  position: absolute;
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
}
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 1px #e2e2e2;
  z-index: 1;
}

.blue-fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.where-talents-work,
.our-partners {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 100px;
  max-width: 1320px;
}

.where-talents-work a,
.our-partners a {
  transition: 0.5s;
  flex-basis: 12.5%;
  max-width: 8rem;
  margin: 0 auto;
}

.where-talents-work img,
.our-partners img {
  max-width: 8rem;
  margin: 0 auto;

  /* width: 100%; */
}

.sponsor-images {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-content: center;
  gap: 40px;
  flex-wrap: wrap;
  align-items: center;
}

.where-talents-work > h3,
.our-partners > h3 {
  font-size: 36px;
  text-align: center;
  margin: 0 auto;
}

.our-partners > p {
  font-size: 18px;
  /* margin-top: -1rem; */
  text-align: center;
  margin: 0 auto;
}

.bottom-about {
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
}

@media only screen and (min-width: 768px) {
  .bottom-about {
    padding: 2rem;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .where-talents-work,
  .our-partners {
    padding: 2rem;
  }

  .sponsor-images {
    justify-content: flex-start;
  }

  .where-talents-work > h3,
  .our-partners > h3 {
    text-align: left;
  }

  .our-partners > p {
    text-align: left;
  }
}





/* homepage styling */
.manrope {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: medium;
  font-style: normal;
}
.slick-dots li.slick-active button:before {
  color: #fdbf17 !important;
}
.slick-dots li button:before {
  width: 30px !important;
  height: 30px !important;
  font-size: 15px !important;
}
.slick-next:before {
  content: "" !important;
}
.slick-prev:before {
  content: "" !important;
}
.slick-prev,
.slick-next {
  top: 92% !important;
}

.slick-next {
  right: 6% !important;
  font-size: 20px !important;
}
.slick-prev {
  z-index: 1 !important;
  left: 6% !important;
}
.slick-dots {
  top: 92% !important;
}

@media screen and (max-width: 1024px) {
  .slick-prev,
  .slick-next {
    top: 110% !important;
  }

  .slick-dots {
    top: 108% !important;
  }
}
@media screen and (max-width: 600px) {
  .slick-dots {
    top: 93% !important;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

/* new font bulb */

.satoshi {
  font-family: "Satoshi-Medium";
}
.inter {
  font-family: "Inter", sans-serif;
}
.inter-400 {
  font-weight: 400;
}
.inter-500 {
  font-weight: 500;
}
.inter-600 {
  font-weight: 600;
}
.inter-700 {
  font-weight: 700;
}
.inter-800 {
  font-weight: 800;
}
.z-10 {
  z-index: 1;
}

.flex-form-label {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.clash-d {
  font-family: "Clash Display", sans-serif;
  /* font-family: 'Roboto', sans-serif; */

  color: #292929;
}
.clash-d2 {
  font-family: "Clash Display", sans-serif;
  color: #292929;
  line-height: 1.2;
}
.hero {
  /* background-color: #e2e5f3; */
  background: url("/src/Assets/thebulb/bulb.png");

  /* background-attachment: fixed; */
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.talent {
  background: url("/src/Assets/thebulb/line.svg");

  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}

.service {
  background: url("/src/Assets/thebulb/Women\ in\ tech.png") no-repeat center
    center / cover;
  position: relative;
}

/* .overlay {
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: rgba(15, 18, 46, 0.7);
} */

.service .overlay {
  background-color: rgba(15, 18, 46, 0.7);
}

/* .block::before {
  background-color: rgba(34, 40, 82, 0.9);
 

  content: "";
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  width: 100%;
} */
.serviceText {
  z-index: 10;
  color: white;
}
.active-page,
.first-page {
  background-color: #f4511e;
  color: white;
}

.skeleton {
  animation: loading 1s linear infinite alternate;
}

.close-timer {
  animation: closeTimer 5s forwards linear;
}

:root {
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: #292929 !important;
  background-color: #fff;

  scroll-behavior: smooth;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
*::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .nav {
    @apply mr-[47px] last:mr-0 text-2xl leading-8 inline-block;
  }
  .active {
    @apply font-medium  border-b-2 px-2;
  }
  .rounded-btn {
    @apply flex items-center justify-center bg-white/[0.2] h-[69.71px] w-[69.71px] rounded-full backdrop-blur-[7.77035px];
  }
  .cart-btn {
    @apply text-[#888888] text-lg pb-2 border-b-[0.5px] border-b-[#747474]  w-1/3;
  }
  .active-cart-btn {
    @apply border-b-[#2F4333] border-b-2  text-[#292929] font-medium;
  }
  .inputStyle {
    @apply text-grey-light focus:outline-0 bg-[#F2F2F2] border-[0.5px] border-[#747474] rounded-[10px] py-3 px-5 mt-2 w-full;
  }
  .blurBox {
    @apply h-[35px] w-[35px] rounded-full blur-[12.5px] -z-10 absolute;
  }
  .mobile-nav {
    @apply text-[1.5rem] leading-[52px]  font-medium my-[9px];
  }
}

.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

.view-overlay:hover div {
  opacity: 1;
}

.gradient {
  background-image: linear-gradient(
    99.99deg,
    #4693ed -26.21%,
    #79c2d2 22.16%,
    rgba(192, 86, 9, 0.49) 111.62%
  );
}

/* .image-bg{
  
  } */

.h-calc {
  height: calc(100vh - 109px);
}

.border-img {
  background: linear-gradient(180deg, #78a3ad 0%, rgba(192, 86, 9, 0.49) 100%);
}
.text-gradient {
  background: linear-gradient(
    94.97deg,
    #78a3ad 24.64%,
    rgba(192, 86, 9, 0.49) 118.54%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.rotate-anim {
  animation: rotate 1s infinite linear;
}

.rotate-anim1 {
  animation: rotate2 1s infinite linear;
}

.w-0 {
  width: 0 !important;
}

.lightbg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(245, 244, 244, 0.24);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.auction-card-wrapper:hover .auction-card {
  width: 80% !important;
  height: 90% !important;
}
.auction-card-wrapper:hover .auction-join {
  opacity: 1 !important;
}

@media (max-width: 768px) {
  .slick-dots li.slick-active button:before {
    color: #fdbf17 !important;
  }
  .slick-dots li button:before {
    width: 20px !important;
    height: 20px !important;
    font-size: 15px !important;
  }
  .slick-next:before {
    content: "" !important;
  }
  .slick-prev:before {
    content: "" !important;
  }
  .slick-prev,
  .slick-next {
    top: 95% !important;
  }
  
  .slick-next {
    right: 6% !important;
    font-size: 20px !important;
  }
  .slick-prev {
    z-index: 1 !important;
    left: 6% !important;
  }
  .slick-dots {
    top: 93% !important;
  }
  .custom-h {
    height: 224px;
  }
}

.grey-color {
  background-color: #999ea5;
}

.green-color {
  background-color: #3ea03b;
}
.background-grey {
  background-color: #b8babc !important;
}

.skeleton-loader div,
.skeleton-loader article {
  animation: anims 1s ease-in infinite alternate-reverse forwards;
}

@keyframes anims {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.like-container svg {
  top: 100%;
  animation: fadeup 5s ease-in forwards;
}
@keyframes fadeup {
  0% {
    opacity: 1;
    top: 100%;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.item:nth-child(odd) img {
  animation: slideInFromLeft 0.7s ease-in-out;
}
.item:nth-child(even) img {
  animation: slideInFromRight 0.7s ease-in-out;
}


.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  top: 0; /* Adjust based on the height of your navbar */
  left: 0;
  padding: 16px;
  z-index: 999; /* Set a z-index lower than the navbar */
}

.bounce-text {
  display: inline-block;

  animation: bounce 2s linear infinite; /* Adjust duration as needed */
}

@keyframes bounce {
  0% {
    transform: scale(1); /* Normal size */
  }
  100% {
    transform: scale(1.2); /* Zoomed in by 20% */
  }
}

@media screen and (max-width: 600px) {
  .hero {
    background-color: #e2e5f3;
    background: url("/src/Assets/thebulb/bulb.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: fixed;
    top: 0; /* Adjust based on the height of your navbar */
    left: 0;
    padding: 16px;
    z-index: 999; /* Set a z-index lower than the navbar */
  }
  .bounce-text {
    animation: marquee 10s linear infinite; /* Adjust duration as needed */
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%); /* Move to the left */
    }
  }
}

