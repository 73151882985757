.engine-hero {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 51px;
	margin-top: 5rem;
	padding: 2rem 1rem;
	/* margin-right: -5rem; */
}

.rocket {
	display: none;
	max-width: 45%;
}

.bg-rocket {
	position: relative;
	/* max-height: 150vh; */
}

.bg-rocket::after {
	content: '';
	background-image: url(../../Assets/img/rocket.png);
	background-size: contain;
	background-position: top center;
	background-repeat: no-repeat;
	height: 150vh;
	position: absolute;
	opacity: 0.08;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;  
}

.engine-hero-text {
	max-width: 583px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.engine-heading {
	font-weight: 700;
	font-size: 32px;
	line-height: 1.3;
}

.engine-para {
	font-size: 20px;
	font-weight: 400;
	line-height: 1.8;
}

.engine-card-container {
	display: flex;
	flex-direction: column;
	gap: 7.5rem;
	justify-content: center;
	padding: 1rem;
	width: 100%;
}

.engine-card-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 2rem;
}

.engine-image-card {
	max-width: 100%;
	width: 500px;
	order: unset;
	z-index: -1;
}

.engine-text-card {
	max-width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1rem;
}

.engine-text-card-heading {
	font-weight: 700;
	font-size: 30px;
	line-height: 1.3;
}

.engine-text-card-description {
	font-weight: 400;
	font-size: 18px;
	line-height: 1.6;
	margin-top: -0.5rem;
}

.second-hero {
	width: 100%;
	background: #6D6D6D1A;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	text-align: center;
	padding: 6.875rem 1rem;
}

.right {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 1rem auto 0;
	padding: 110px 1rem;
}

.sec-h2 {
	font-size: 30px;
	line-height: 1.3;
	font-weight: 700;
	max-width: 628px;
}

.sec-p {
	font-size: 18px;
	max-width: 573px;
	line-height: 1.8;
}

.imagine {
	margin: 100px 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1rem;
}

.imagine-head {
	font-weight: 700;
	font-size: 30px;
	line-height: 1.3;
}

.imagine-para {
	font-weight: 400;
	font-size: 18px;
	line-height: 1.7;
	max-width: 614px;
}

.bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding: 150px 1rem 50px;
}

.bottom-h3 {
	max-width: 820px;
	text-align: center;
	font-size: 32px;
	font-weight: 700;
	line-height: 1.5;
}

.bottom-p {
	max-width: 609px;
	font-size: 20px;
	text-align: center;
	line-height: 1.8;
}

.reverse {
	flex-direction: column-reverse;
}
.companies {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	margin: 8rem auto 0rem;
	max-width: 1164px;
}

.companies-heading {
	text-align: center;
	font-weight: 700;
	font-size: 30px;
}

.companies-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3rem;
	flex-wrap: wrap;
	padding: 0 1rem;
}

.companies-logo > img {
	max-width: 6rem;
}

@media only screen and (min-width: 768px) {
	.engine-hero {
		justify-content: flex-start;
		padding: 2rem;
	}

	.engine-heading {
		font-size: 42px;
		line-height: 50.4px;
	}
	
	.engine-para {
		font-size: 24px;
		line-height: 44.64px;
	}

	.engine-card-container {
		margin: 2rem auto 0;
		padding: 0 2rem;
		gap: 8.5rem;
	}

	.engine-image-card {
		/* margin-top: -4rem; */
		min-width: 345px;
	}

	.engine-text-card {
		gap: 1.5rem;
	}
	
	.engine-text-card-heading {
		font-size: 32px;
	}
	
	.engine-text-card-description {
		font-size: 20px;
		line-height: 1.8;
	}

	.sec-h2 {
		font-size: 38px;
		line-height: 52.74px;
	}
	
	.sec-p {
		font-size: 20px;
		max-width: 573px;
		line-height: 37.2px;
	}

	.imagine-head {
		font-size: 38px;
	}
	
	.imagine-para {
		font-size: 20px;
		line-height: 37.2px;
	}
}

@media only screen and (min-width: 1024px) {
	.engine-hero {
		justify-content: center;
		padding: 0 2rem;
	}
	
	.rocket {
		display: flex;
	}

	.bg-rocket::after {
		/* transform: rotate(2.22deg); */
		margin-left: -1rem;
		max-height: 160vh;
	}

	.engine-card-container {
		margin-top: 6rem;
	}

	.engine-card-content {
		flex-direction: row;
		align-items: center;
		gap: 3rem;
	}

	.engine-image-card {
		order: unset;
	}

	.reverse {
		flex-direction: row;
	}
}