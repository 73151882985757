.fluid-img {
  margin-top: 20%;
}
.fluid-img img {
  margin-bottom: 10px;
  width: 18px;
  transition: all 0.5s ease;
  animation: upDown 1.5s infinite;
  -webkit-animation: upDown 1.5s infinite;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  cursor: pointer;
}

.hero-youtube {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.hero-box {
  flex-direction: column;
  gap: 50px;
  padding: 8rem 0;
  /* min-height: 100vh; */
  /* height: fit-content; */
}

.hero-text-box {
  gap: 2rem;
}
.fixxed {
  /* max-height: 4rem; */
  width: 1rem;
  height: 5rem;
}

@media only screen and (min-width: 768px) {
  .hero-box {
    flex-direction: row;
    min-height: 70vh;
  }
  .fixxed{
    width: 1rem;
    height: 7rem;
    display: flex;
    align-items: center;
  }
  /* .hero-text-box>Text {
  text-align: left;;
} */
}

@media only screen and (min-width: 1024px) {
  .hero-box {
    flex-direction: row;
    min-height: 676px;
  }
}
