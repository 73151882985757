.how-hire-developering {
	/* background: #f5f9ff; */
}
.hire-developer .heading {
	font-weight: bold;
	font-size: 32px;
	line-height: 39px;
	color: #050038;
}
.hire-developer .button {
	width: 195px;
	height: 60px;
	background: #050038;
	border-radius: 6px;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.hire-developer .button a {
	color: #ffffff;
	text-decoration: none;
}
.hire-developer .text {
	font-weight: normal;
	font-size: 18px;
	line-height: 38px;
	text-align: center;
	color: #050038;
}
.hire-developer .top .heading {
	font-size: 42px;
	line-height: 51px;
}
.hire-developer ul {
	font-weight: normal;
	font-size: 18px;
	line-height: 34px;

	color: #050038;
}
.hire-developer ul li {
	list-style: none;
}
.hire-developer ul li .heading {
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	text-align: left;
	color: #050038;
	margin-bottom: 0.5em;
}
.hire-developer ul li .text {
	font-weight: normal;
	font-size: 18px;
	line-height: 38px;
	text-align: left;
}
.hire-developer ul .star-img {
	width: 30px;
	height: 30px;
}

.hire-developer .big-img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	/* object-position: bottom; */
}
.train-section {
	margin: 0 auto;
}

.train-section .training-card {
	width: 336px;
	/* height: 418px; */
	background: rgba(59, 107, 211, 0.03);
	border-radius: 6px;
	cursor: default;
	margin: 0 auto;
}
@media (max-width: 768px) {
	.train-section .training-card {
		width: 100%;
	}
}
.train-section .training-card .heading {
	font-weight: bold;
	font-size: 28px;
	line-height: 34px;

	color: rgba(59, 107, 211, 0.7);
}

.hire-developer .number {
	font-weight: bold;
	font-size: 28px;
	line-height: 34px;
	color: rgba(59, 107, 211, 0.7);
	margin-bottom: 0.5em;
}
/* form */

.hire-developer-form {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffff;
}
@media (max-width: 768px) {
	.hire-developer-form {
		position: relative;
	}
}
.hire-developer-form .close {
	margin-left: auto;
	cursor: pointer;
	opacity: 0.9 !important;
}
.hire-developer-form .heading {
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
	color: #10162b;
}
.hire-developer-form form label {
	margin-bottom: -90px;
	background-color: #fff;
	margin-left: 20px;
}
.hire-developer-form form input,
.hire-developer-form form select {
	margin-top: -10px;
	height: 56px;
	border: 0.4px solid rgba(59, 107, 211, 0.8);
	box-sizing: border-box;
	border-radius: 4px;
}
.hire-developer-form form input:active,
.hire-developer-form form select:active {
	outline: none !important;
	border-style: none !important;
}
.hire-developer-form form .required,
.required-non {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: -8px;
	margin-left: 25px;
	color: #bd1919;
}
.hire-developer-form form .required-non {
	opacity: 0;
}
.hire-developer-form .file-upload label {
	border: 0.4px dashed #3b6bd3;
	box-sizing: border-box;
	border-radius: 4px;
	width: 222px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 10px;
}
.hire-developer-form form .button {
	width: 195px;
	height: 60px;
	background: #050038;
	border-radius: 6px;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	/* margin-top: 7em; */
}
input[type='file']::file-selector-button,
input[type='file']::-webkit-file-upload-button {
	background-color: brown;
}

.hire-developer-form .img {
	width: 20px;
	height: 20px;
	margin-left: auto;
	cursor: pointer;
	margin-bottom: 1em;
}
.hire-developer-form .img img {
	object-fit: contain;
	width: 100%;
}
