.course {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 561px;
}
.course > .img-cont {
  height: 370px;
  border-radius: 12px;
  /* font-family: "proxima-nova"; */
}

.img-cont > img {
  border-radius: 12px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.course > h2 {
  width: fit-content;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: inline-block;
  text-decoration: none;
  background-image: linear-gradient(to right, #fee7aa, #fee7aa);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 30% 4px;
  transition: background-size 0.5s ease;
}

.course > h2:hover {
  background-size: 100% 4px;
}
.course > p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.mini {
  display: flex;
  gap: 1.5rem;
}
.mini > img {
  max-width: 45px;
}
.mini > p {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
}
